<template>
  <div class="text-white x-footer">
    <div class="container-fluid">
      <div class="row mb-4">
        <div class="col-lg-4">
          <img
            src="/images/bitbromo-logo.png"
            alt="bitbromo-logo"
            style="height: 42px; margin-bottom: 34px; margin-right: 7px"
          />
          <p class="x-footer-web-info">
            Take your crypto to the next level with BitBromo
          </p>
        </div>
        <div class="col-lg-2 link_w">
          <h5>Links</h5>
          <div>
            <ul class="x-pages">
              <li>
                <router-link to="/pages/privacy-policy" class="link_f link_g"
                  >Privacy Policy</router-link
                >
              </li>
              <li>
                <router-link
                  to="/pages/term-and-conditions"
                  class="link_f link_g"
                >
                  AML & KYC policy
                </router-link>
              </li>
              <li>
                <router-link
                  to="/pages/term-and-conditions"
                  class="link_f link_g"
                >
                  Cookie Policy
                </router-link>
              </li>
              <li>
                <router-link
                  to="/pages/term-and-conditions"
                  class="link_f link_g"
                >
                  Term of rules
                </router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-lg-2 link_w">
          <h5>Customer care service</h5>
          <div>
            <ul class="x-pages">
              <li>
                <a href="mailto:support@bitbromo.com" class="link_f link_g"
                  >support@bitbromo.com</a
                >
              </li>
              <li>
                <router-link to="/pages/contact-us" class="link_f link_g">
                  Hubungi Kami
                </router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-lg-2 link_w">
          <h5>Address</h5>
          <div>
            <ul class="x-pages">
              <li>
                <span class="link_f"
                  >Menara Rajawali Lt.11 <br />Jl Dr Ide Anak Augn Gede Agung
                  lot 5.1 Kawasan Mega Kuningan, Jakarta 12950, Indonesia</span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row x-footer-bottom-section">
        <div class="col link_f">
          Copyright @ 2023 PT Everest Crypto. All Right Reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// node_modules

// applibs
import themain from "@/applibs/themain.js";

export default {
  components: {},
  data() {
    return {
      themain,
    };
  },

  async mounted() {},

  methods: {},
};
</script>

<style scoped>
.x-footer {
  padding-top: 46px;
  padding-bottom: 20px;
  /* margin-top: 60px; */
  width: 100%;
  background-color: #251e48;
  /* height: 100%; */
  /* position: absolute;
  bottom: 0; */
}

ul.x-pages {
  list-style-type: none;
  padding-left: 0px;
  font-size: 0.9rem;
  line-height: 2rem;
  font-weight: 300;
}
ul.x-pages a {
  text-decoration: none;
}

.link_f {
  color: #7c769c;
}

.link_g:hover {
  color: #ec2a06;
}

.x-footer-bottom-section {
  border-top: 0.2px solid #b9b9b9;
  padding-top: 10px;
}

.x-footer-web-info {
  font-size: 0.8rem;
  color: #7c769c;
}

@media only screen and (max-width: 500px) {
  .link_w {
    margin-top: 15px;
  }

  .link_w h5 {
    font-size: 16px;
  }

  .link_f {
    font-size: 14px;
  }
}
</style>
