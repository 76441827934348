<template>
  <div class="col-lg-12 ctr_trm">
    <h2 class="head_txt">Peraturan Perdagangan (Trading Rules)</h2>
    <div>
      <br />
    </div>
    <div>
      <ol type="I" class="text_h">
        <li>
          <p lang="en-ID" align="justify">
            <strong>DEFINISI</strong>
          </p>
        </li>
      </ol>
      <ol>
        <ol type="a">
          <ol type="i">
            <ol>
              <li>
                <p lang="en-ID" align="justify">
                  Badan Pengawas Perdagangan Berjangka Komoditi yang selanjutnya
                  disebut Bappebti adalah lembaga pemerintah yang tugas pokoknya
                  melakukan pembinaan, pengaturan, pengembangan, dan pengawasan
                  Perdagangan Berjangka;
                </p>
              </li>
              <li>
                <p lang="en-ID" align="justify">
                  Pasar Fisik Aset Kripto adalah Pasar Fisik Aset Kripto yang
                  dilaksanakan menggunakan sarana eletkronik yang difasilitasi
                  oleh Bursa Berjangka atau sarana elektronik yang dimiliki oleh
                  Calon Pedagang Fisik Aset Kripto untuk jual atau beli Aset
                  Kripto;
                </p>
              </li>
              <li>
                <p lang="en-ID" align="justify">
                  Aset Kripto adalah Komoditi tidak terwujud yang berbentuk
                  digital aset, menggunakan kriptografi, jaringan
                  <em>peer to peer,</em>dan buku besar yang terdistribusi untuk
                  mengatur penciptaan unit baru, memverifikasi transaksi, dan
                  mengamankan transaksi tanpa campur tangan pihak lain;
                </p>
              </li>
              <li>
                <p lang="en-ID" align="justify">
                  Pedagang Fisik Aset Kripto adalah pihak yang telah memperoleh
                  persetujuan dari Kepala BAPPEBTI untuk melakukan transaksi
                  Aset Kripto baik atas nama diri sendiri, dan/ atau
                  memfasilitasi transaksi Pelanggan Aset Kripto;
                </p>
              </li>
              <li>
                <p lang="en-ID" align="justify">
                  Wallet adalah media yang dipergunakan untuk menympan aset
                  kripto, baik berupa koin ataupun token;
                </p>
              </li>
              <li>
                <p lang="en-ID" align="justify">
                  Token adalah salah satu bentuk Aset Kripto yang dibuat sebagai
                  porduk turunan dari koin;
                </p>
              </li>
              <li>
                <p lang="en-ID" align="justify">
                  Koin adalah salah satu bentuk Aset Kripto yang memiliki
                  konfigurasi
                  <em>blockchain</em>tersendiri dan memiliki karakteristik
                  seperti Aset Kripto yang muncul pertama kali, yaitu
                  <em>bitcoin.</em>
                </p>
              </li>
            </ol>
          </ol>
        </ol>
      </ol>
      <ol type="I" start="2" class="text_h">
        <li>
          <p lang="en-ID" align="justify">
            <strong>SYARAT DAN KETENTUAN UMUM</strong>
          </p>
        </li>
      </ol>
      <p lang="en-ID" align="justify">
        Syarat dan Ketentuan Umum Pelanggan PT. Everest Kripto Indonesia
        (selanjutnya semua lampiran, perubahan dan/atau penambahan dan pembaruan
        selanjutnya disebut “SKU”), yang memuat syarat dan ketentuan mengenai
        pelangganan produk, layanan, teknologi yang disediakan oleh PT. Bursa
        Kripto Indonesia (selanjutnya disebut “Website”) termasuk semua
        fasilitas dan fitur layanan dalam Website, sepanjang tidak diatur secara
        khusus oleh nama-nama individu, alamat individu, dan alamat e-mail
        sebagaimana tercantum dalam PT. Everest Kripto Indonesia (selanjutnya
        disebut “Pelanggan”), yang dibuat pada hari dan tanggal sebagaimana
        tercantum pada bagian pendaftaran akun bursa kripto dan merupakan satu
        kesatuan, tidak terpisahkan dan merupakan kesepakatan pada SKU ini.
      </p>
      <p lang="en-ID" align="justify">
        <a name="_heading=h.gjdgxs"></a>Dengan mendaftar menjadi pelanggan
        Website, Anda menyatakan bahwa Anda telah MEMBACA, MEMAHAMI, MENYETUJUI
        dan MEMATUHI Syarat dan Ketentuan di bawah ini. Anda disarankan untuk
        membaca semua syarat dan ketentuan dengan seksama sebelum menggunakan
        Situs Web atau segala bentuk layanan yang disediakan oleh Situs dan
        dengan ini Anda setuju dan mengikatkan diri pada semua kegiatan dalam
        SKU ini dengan syarat dan ketentuan umum berikut:
      </p>
      <p lang="en-ID" align="justify">
        Selama konteks kalimat tidak menentukan lain, istilah atau definisi
        dalam SKU memiliki arti sebagai berikut:
      </p>
      <ol>
        <li>
          <p lang="en-ID" align="justify">
            Website mengacu pada situs online dengan alamat www.bitbromo.com.
            “Situs Web” juga dapat merujuk ke layanan, produk, situs, konten,
            atau materi lain yang disediakan oleh PT. Everest Kripto Indonesia.
          </p>
        </li>
      </ol>
      <ol start="2">
        <li>
          <p lang="en-ID" align="justify">
            Aset Kripto (Crypto Asset) yang selanjutnya disebut Aset Kripto
            adalah Komoditi tidak berwujud yang berbentuk digital, menggunakan
            kriptografi, jaringan informasi teknologi, dan buku besar yang
            terdistribusi, untuk mengatur penciptaan unit baru, memverifikasi
            transaksi, dan mengamankan transaksi tanpa campur tangan pihak lain.
          </p>
        </li>
      </ol>
      <ol start="3">
        <li>
          <p lang="en-ID" align="justify">
            Registrasi adalah halaman pendaftaran Website yang memuat data,
            informasi, pernyataan dan fasilitas Website yang dapat diperoleh
            pelanggan setelah melalui proses registrasi yang merupakan satu
            kesatuan dan bagian yang tidak terpisahkan dari SKU.
          </p>
        </li>
      </ol>
      <ol start="4">
        <li>
          <p lang="en-ID" align="justify">
            Layanan adalah layanan yang diberikan oleh PT. Bursa Kripto
            Indonesia memungkinkan pembeli dan penjual untuk membeli dan menjual
            aset kripto secara online dalam Rupiah.
          </p>
        </li>
      </ol>
      <ol start="5">
        <li>
          <p lang="en-ID" align="justify">
            Aktivitas Perdagangan adalah transaksi perdagangan aset kripto yang
            dilakukan di situs web Everest Kripto Indonesia.
          </p>
        </li>
      </ol>
      <ol start="6">
        <li>
          <p lang="en-ID" align="justify">
            Akun adalah akses yang diberikan kepada pelanggan untuk mendapatkan
            informasi dan untuk melakukan aktivitas perdagangan aset kripto
            melalui Website.
          </p>
        </li>
      </ol>
      <ol start="7">
        <li>
          <p lang="en-ID" align="justify">
            Password adalah kumpulan karakter atau string yang terdiri dari
            rangkaian alfanumerik atau kombinasi keduanya dan digunakan oleh
            pelanggan untuk memverifikasi identitasnya ke sistem keamanan yang
            dimiliki oleh jaringan di Situs Web.
          </p>
        </li>
      </ol>
      <ol start="8">
        <li>
          <p lang="en-ID" align="justify">
            Nomor Telepon adalah nomor telepon pelanggan yang telah terdaftar
            untuk memperoleh otorisasi untuk memperoleh layanan. Mengubah nomor
            telepon dapat dilakukan dengan mengikuti aturan yang tertulis di
            halaman bantuan yang tersedia di Situs Web.
          </p>
        </li>
      </ol>
      <ol start="9">
        <li>
          <p lang="en-ID" align="justify">
            Konversi adalah perubahan nilai mata uang ke dalam bentuk atau
            format digital dalam aset ini bitcoin dan aset kripto berdasarkan
            kurs yang berlaku dan sebaliknya.
          </p>
        </li>
      </ol>
      <ol start="10">
        <li>
          <p lang="en-ID" align="justify">
            Verifikasi adalah pemeriksaan terhadap proses pendaftaran terhadap
            kekhususan calon pelanggan dan informasi pribadi, sebelum disetujui
            atau ditolaknya proses pendaftaran menjadi pelanggan.
          </p>
        </li>
      </ol>
      <ol start="11">
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan Aset Kripto adalah pihak yang menggunakan jasa Pedagang
            Fisik Aset Kripto untuk membeli atau menjual Aset Kripto yang
            diperdagangkan di Pasar Fisik Aset Kripto
          </p>
        </li>
      </ol>
      <ol start="12">
        <li>
          <p lang="en-ID" align="justify">
            Deposit adalah proses penyimpanan aset kripto atau rupiah oleh
            pelanggan. Dalam syarat dan ketentuan ini, mengacu pada proses atau
            mekanisme penyetoran tambahan (top-up) untuk saldo rupiah, aset
            kripto yang prosesnya dilakukan dengan menggunakan akun milik
            pelanggan di bursa kripto.
          </p>
        </li>
      </ol>
      <ol start="13">
        <li>
          <p lang="en-ID" align="justify">
            Withdraw adalah penarikan aset kripto atau rupiah lainnya oleh
            pelanggan. Dalam syarat dan ketentuan, penarikan juga mengacu pada
            proses pemindahan atau penarikan aset kripto, atau rupiah dari akun
            bursa kripto ke dompet atau rekening bank lain. Dalam pengertian
            ini, mekanisme penarikan hanya dapat dilakukan pada rekening bank
            yang nama pemilik rekening bank nya sama dengan nama pemilik
            rekening.
          </p>
        </li>
      </ol>
      <ol start="14">
        <li>
          <p lang="en-ID" align="justify">
            Harga adalah harga aset kripto di Situs Web.
          </p>
        </li>
      </ol>
      <ol start="15">
        <li>
          <p lang="en-ID" align="justify">
            Alamat Kripto / Crypto Address adalah alamat yang tertera pada
            'Deposit' yang dibuat khusus untuk pelanggan dan dapat digunakan
            berkali-kali. Dalam faktor teknisnya, alamat aset kripto memiliki
            peran atau fungsi untuk menerima aset kripto yang dikirim oleh
            pelanggan dari tempat/dompet lain ke akun bursa kripto.
          </p>
        </li>
      </ol>
      <ol start="16">
        <li>
          <p lang="en-ID" align="justify">
            Batas Transfer adalah batas minimum dan maksimum untuk mengirim aset
            kripto. Batas penarikan juga dikenakan dengan batas penarikan aset
            kripto ke dompet lain dan perlu dijelaskan bahwa setiap akun akan
            memiliki batas penarikan yang berbeda yang disesuaikan dengan Syarat
            dan Ketentuan ini dan peraturan perundang-undangan yang berlaku.
          </p>
        </li>
      </ol>
      <ol start="17">
        <li>
          <p lang="en-ID" align="justify">
            Batas Tarik Tunai adalah batas jumlah minimum dan maksimum penarikan
            dalam penarikan atau pencairan rupiah per-hari. Berkenaan dengan
            batas penarikan rupiah, perlu dijelaskan bahwa setiap rekening akan
            memiliki batas penarikan yang berbeda-beda yang disesuaikan dengan
            Syarat dan Ketentuan ini serta peraturan perundang-undangan yang
            berlaku.
          </p>
        </li>
      </ol>
      <ol start="18">
        <li>
          <p lang="en-ID" align="justify">
            Rekening Bank adalah rekening bank yang telah didaftarkan oleh
            pelanggan dengan kesamaan nama pemegang rekening kewajiban dan nama
            pelanggan di Website.
          </p>
        </li>
      </ol>
      <ol start="19">
        <li>
          <p lang="en-ID" align="justify">
            Order Book adalah daftar harga jual dan harga beli yang tersedia di
            Website. Pelanggan dapat membeli atau menjual aset kripto
            menggunakan harga yang tertera dan untuk memudahkan pelanggan, Order
            Book dibagi menjadi dua bagian, yaitu:
          </p>
          <ol>
            <li>
              <p lang="en-ID" align="justify">
                Daftar permintaan pembelian aset kripto lengkap dengan jumlah
                dan harga yang ditawarkan
              </p>
            </li>
            <li>
              <p lang="en-ID" align="justify">
                Daftar aset kripto yang dijual lengkap dengan jumlah dan harga
                yang diminta.
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <ol start="20">
        <li>
          <p lang="en-ID" align="justify">
            E-Wallet adalah komponen perangkat lunak dan informasi tentang
            penyimpanan dan pelangganan aset kripto.
          </p>
        </li>
      </ol>
      <ol start="21">
        <li>
          <p lang="en-ID" align="justify">
            Uang adalah mata uang Negara Kesatuan Republik Indonesia yaitu
            Rupiah. Mata uang yang diperdagangkan terhadap aset kripto di Bursa
            Kripto Indonesia dan syarat dan ketentuan ini mengacu pada mata uang
            negara Republik Indonesia adalah Rupiah.
          </p>
        </li>
      </ol>
      <ol start="22">
        <li>
          <p lang="en-ID" align="justify">
            KYC (Know Your Customer Principles) adalah proses penilaian calon
            pelanggan dan pelanggan untuk mengetahui latar belakang dan itikad
            baik dari akta yang akan dilakukan dalam suatu aktivitas trading
            Bitcoin.
          </p>
        </li>
      </ol>
      <ol start="23">
        <li>
          <p lang="en-ID" align="justify">
            AML (Anti Money Laundering) merupakan kegiatan untuk mengantisipasi
            dan menghentikan praktik money laundering.
          </p>
        </li>
      </ol>
      <ol type="I" start="3" class="text_h">
        <li>
          <p lang="en-ID" align="justify">
            <strong>PROSES PENDAFTARAN PELANGGAN ASET KRIPTO</strong>
          </p>
        </li>
      </ol>
      <p
        lang="en-ID"
        align="justify"
        class="font-weight-bold"
        style="font-size: 20px"
      >
        Persyaratan untuk menjadi pelanggan adalah:
      </p>
      <ol>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan dapat melakukan proses pendaftaran melalui Website:
          </p>
        </li>
      </ol>
      <ul>
        <li>
          <p lang="en-ID" align="justify">
            Setuju untuk mematuhi Syarat dan Ketentuan Umum (SKU);
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Berusia 18 tahun atau lebih, atau sudah menikah;
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">Memiliki identitas diri.</p>
        </li>
      </ul>
      <ol start="2">
        <li>
          <p lang="en-ID" align="justify">
            Pada setiap proses pendaftaran, seorang calon pelanggan harus
            menunjukkan semua data berupa:
          </p>
        </li>
      </ol>
      <ul>
        <li>
          <p lang="en-ID" align="justify">Nama (sesuai Identitas terlampir);</p>
        </li>
        <li>
          <p lang="en-ID" align="justify">Alamat rumah sesuai identitas;</p>
        </li>
        <li>
          <p lang="en-ID" align="justify">Alamat tinggal saat ini;</p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Nomor atau Ponsel (nomor harus diaktifkan dan digunakan secara
            pribadi);
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Tempat dan tanggal lahir (sesuai KTP harus dilampirkan);
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">Kebangsaan;</p>
        </li>
        <li>
          <p lang="en-ID" align="justify">Jenis kelamin;</p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Kartu identitas berfoto masih berlaku. Kartu identitas yang dapat
            digunakan adalah: Kartu Tanda Penduduk (KTP), Paspor, Kartu Izin
            Tinggal Terbatas (KITAS);
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">Pekerjaan;</p>
        </li>
        <li>
          <p lang="en-ID" align="justify">Berlaku email;</p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Dan/atau segala sesuatu yang diminta dan dipersyaratkan sehubungan
            dengan syarat pendaftaran telah ditentukan, dan calon pelanggan
            dengan ini menyatakan dan menjamin bahwa semua
            data/informasi/dokumen/informasi/setiap pernyataan yang diberikan
            mengenai proses pendaftaran sebagai pelanggan suatu Website adalah
            lengkap. Asli, benar dan sesuai dengan keadaan yang sebenarnya serta
            data / informasi / dokumen / informasi / tanggal pernyataan yang
            tidak/tidak diubah dan masih berlaku/kadaluarsa dan tidak/tidak ada
            perubahan atau syarat lain yang disetujui berdasarkan kebijakan di
            halaman pendaftaran di Website.
          </p>
        </li>
      </ul>
      <ol start="3">
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan dengan ini setuju bahwa proses menjadi pelanggan Bursa
            Kripto Indonesia akan berlaku efektif hanya setelah semua
            persyaratan bursa kripto dipenuhi oleh pelanggan dan proses
            pendaftaran telah melalui proses verifikasi untuk disetujui bursa
            kripto. Segala resiko yang timbul sehubungan dengan
            penutupan/pemblokiran/pembekuan akun karena kesalahan dan/atau
            kelalaian pelanggan, akan menjadi tanggung jawab pelanggan dan bursa
            kripto tidak akan ganti rugi kepada pelanggan atau pihak manapun
            dengan cara apapun tuntutan/tuntutan dan ganti rugi dari pelanggan
            atau pihak manapun sehubungan dengan penutupan rekening.
          </p>
        </li>
      </ol>
      <ol start="3">
        <li>
          <p lang="en-ID" align="justify">
            Semua data, informasi, keterangan, pernyataan, dokumen yang
            diperoleh bursa kripto sehubungan dengan pelanggan, akan menjadi
            milik bursa kripto dan bursa kripto berhak untuk melakukan
            verifikasi, pencocokan, penilaian, penyembunyian atau pelangganan
            untuk kepentingan manfaat bursa kripto sesuai dengan hukum yang
            berlaku bursa kripto tanpa kewajiban untuk pelangganitahukan atau
            meminta persetujuan, jaminan atau ganti rugi dalam bentuk apapun dan
            dengan alasan apapun kepada pelanggan.
          </p>
        </li>
      </ol>
      <ol start="3">
        <li>
          <p lang="en-ID" align="justify">
            PT. Everest Kripto Indonesia akan mengatur, mengelola dan mengawasi
            sesuai dengan prosedur/prosedur yang ditetapkan PT. Bursa Kripto
            Indonesia atas semua data, informasi, keterangan, pernyataan,
            dokumen atau apapun yang berhubungan dengan pelanggan atau kegiatan
            usaha atau transaksi pelanggan yang terkait dengan akun milik
            pelanggan.
          </p>
        </li>
      </ol>
      <ol start="3">
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan dengan ini menyetujui dan mengizinkan bursa kripto untuk
            menggunakan semua data, informasi dan informasi yang diperoleh
            mengenai pelanggan bursa kripto termasuk tetapi tidak terbatas pada
            pelangganan sarana komunikasi pribadi pelanggan untuk semua tujuan
            lain sejauh mungkin dan diperbolehkan oleh hukum – hukum yang
            berlaku yang ditujukan untuk pemasaran produk – produk bursa kripto
            atau produk pihak lain bekerjasama dengan bursa kripto. Untuk
            menggunakan data yang memerlukan persetujuan dari pihak lain, dengan
            pelanggan tersebut menyatakan bahwa pelanggan tersebut telah
            menggunakan persetujuan tertulis kepada pihak ketiga manapun untuk
            pelangganan data, informasi dan informasi tersebut, dan oleh karena
            itu bursa kripto dengan ini tidak akan mendapatkan kompensasi
            dan/atau pertanggungjawaban dalam bentuk apapun kepada pelanggan
            pihak manapun dan di atas semua risiko, tuntutan, tuntutan hukum
            atau kerugian yang mungkin timbul di kemudian hari sehubungan dengan
            pelangganan data, informasi dan informasi yang telah memperoleh
            persetujuan tertulis dari bursa kripto.
          </p>
        </li>
      </ol>
      <ol type="I" start="4" class="text_h">
        <li>
          <h2 lang="id-ID" align="justify">
            <a name="_Hlk1410944031"></a
            ><a name="_heading=h.3znysh7"></a>IDENTIFIKASI DAN NAMA AKUN
          </h2>
        </li>
      </ol>
      <ol>
        <li>
          <p lang="en-ID" align="justify">
            Setiap akun yang dibuka akan di administrasikan Bursa Kripto
            Indonesia berdasarkan identifikasi khusus dengan nama pelanggan yang
            akan melamar serta nama/identitas akun yang tertera pada identitas
            pelanggan. Pelanggan dilarang menggunakan akun pelanggan selain akun
            milik pelanggan, atau untuk mengakses pelanggan lain, atau membantu
            orang lain untuk mendapatkan akses tidak sah ke akun tersebut.
            Seluruh akun yang menjadi pelanggan merupakan tanggung jawab penuh
            dari pemilik akun yang tercatat dalam sistem website.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan harus menggunakan dan menyertakan email pelanggan dan
            password yang diverifikasi Everest Kripto Indonesia pada saat
            pendaftaran. sistem bursa kripto akan secara otomatis menolak
            layanan pada akun Anda, jika tidak disertai dengan email dan
            password yang benar. Pelanggan bertanggung jawab untuk menjaga
            kerahasiaan kata sandi, akun, PIN, akses lebih banyak dompet Aset
            kripto, akses login email, dan jenis aktivitas yang mencakup
            transaksi di akun pelanggan. Pelanggan bertanggung jawab penuh atas
            pelangganan kata sandi dan akun di bursa kripto. Dalam hal
            pelangganan kata sandi dan/atau akun tanpa izin pelanggan dan kasus
            dugaan pelanggaran lainnya, pelanggan harus menghubungi bursa kripto
            dan mengirim email ke operation@bitbromo.com disertai
            dengan informasi pendukung. Everest Kripto Indonesia tidak
            bertanggung jawab atas segala kerugian yang timbul atas
            penyalahgunaan akun dan/atau kata sandi, dengan atau tanpa
            sepengetahuan pelanggan.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            <a name="_Hlk141094452"></a
            ><a name="_heading=h.2et92p0"></a>Pelanggan setuju untuk tidak
            menggunakan layanan yang disediakan oleh Situs Web untuk melakukan
            tindakan kriminal dalam bentuk apapun, termasuk namun tidak terbatas
            pada, pencucian uang, perjudian, pembelian barang ilegal, aktivitas
            teroris, atau aktivitas peretasan. Setiap pelanggan yang melanggar
            aturan ini dapat diberhentikan dan bertanggung jawab atas kerugian
            yang ditimbulkan kepada PT Everest Kripto Indonesia atau pelanggan
            lain di Situs Web. PT Everest Kripto Indonesia berhak untuk menutup
            akun dan membekukan dana, aset kripto, dan transaksi di dalamnya
            jika menemukan aktivitas mencurigakan di akun hingga waktu yang
            tidak ditentukan.
          </p>
        </li>
      </ol>
      <h2 lang="id-ID" align="justify">Pendaftaran Aplikasi</h2>
      <ol>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan melakukan registrasi dengan menginputkan email dan
            password
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Aplikasi mengirimkan email verifikasi kepada pelanggan
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan mengklik email dan aplikasi dengan otomatis memverifikasi
            email tersebut
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan dinyatakan berhasil di verifikasi.
          </p>
        </li>
      </ol>
      <h2 lang="id-ID" align="justify">
        <a name="_heading=h.lxqm5fxs0nne"></a>Masuk aplikasi
      </h2>
      <ol>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan memasukkan email dan password.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Jika pelanggan telah mengaktifkan 2 Faktor Autentikasi (2FA)
            aplikasi akan meminta 2FA. Jika tidak, aplikasi akan langsung
            memberikan akses masuk.
          </p>
        </li>
      </ol>
      <h2 lang="id-ID" align="justify">
        <a name="_heading=h.rj4e9xjpqrpn"></a>Know Your Customer (KYC)
      </h2>
      <ol>
        <li>
          <p lang="en-ID" align="justify">
            Sebelum dapat bertransaksi pelanggan diwajibkan mengisi formulir
            KYC.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Aplikasi mengarahkan kepada pilihan warga Indonesia atau warga
            asing.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">Pelanggan melakukan pilihan</p>
          <ol type="a">
            <li>
              <p lang="en-ID" align="justify">Untuk pilihan Indonesia;</p>
              <ol type="i">
                <li>
                  <p lang="en-ID" align="justify">
                    Pelanggan menginputkan; nama lengkap, nomor telepon, tempat
                    lahir, tanggal lahir, alamat, pekerjaan, nomor identitas,
                    dan NPWP (pilihan).
                  </p>
                </li>
                <li>
                  <p lang="en-ID" align="justify">
                    Pelanggan juga melakukan <em>liveness video</em>
                    dan disimpan di server sebagai bukti.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p lang="en-ID" align="justify">
                Untuk pilihan warga bukan Indonesia;
              </p>
              <ol type="i">
                <li>
                  <p lang="en-ID" align="justify">
                    Pelanggan menginputkan; nama lengkap, nomor telepon, tempat
                    lahir, tanggal lahir, alamat, pekerjaan, passport.
                  </p>
                </li>
                <li>
                  <p lang="en-ID" align="justify">
                    Pelanggan juga melakukan <em>liveness video</em>
                    dan disimpan di server sebagai bukti.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pada <em>back office</em>, Admin akan memverifikasi KYC ini.
          </p>
        </li>
      </ol>
      <ol type="I" start="5" class="text_h">
        <li>
          <p lang="en-ID" align="justify">
            <strong>PERNYATAAN DAN JAMINAN</strong>
          </p>
        </li>
      </ol>
      <ol type="a">
        <li>
          <p lang="id-ID" align="justify">
            Semua layanan dalam situs Calon Pedagang Fisik Aset Kripto tidak
            memberikan jaminan ataupun garansi apapun dan Calon Pedagang Fisik
            Aset Kripto akan selalu dapat diakses setiap waktu.
          </p>
        </li>
        <li>
          <p lang="id-ID" align="justify">
            Pelanggan Aset Kripto menyatakan dan menjamin akan menggunakan
            Layanan dengan baik dan penuh tanggung jawab serta tidak akan
            melakukan Tindakan yang berlawanan dengan hukum, undang-undang serta
            peraturan yang berlaku di wilayah Republik Indonesia,
          </p>
        </li>
        <li>
          <p lang="id-ID" align="justify">
            Pelanggan Aset Kripto menyatakan dan menjamin tidak akan menggunakan
            Layanan dalam perdagangan Aset Kripto yang berhubungan dengan (i)
            Narkotika, bahan dan/atau zat kimia untuk penelitian; (ii) uang dan
            apapun yang sejenis dengan uang, termasuk derivative; (iii) barang
            dan/atau jasa yang melanggar Hak Kekayaan Intelektual; (iv) amunisi,
            senjata api, bahan peledak, senjata tajam sesuai dengan Ketentuan
            Hukum Berlaku; (v) barang dan/atau jasa yang menunjukan informasi
            pribadi dari pihak ketiga yang melanggar hukum; (vi) barang dan/atau
            jasa yang berhubungan dengan pembelian lotre, lay-away; (vii) jasa
            yang terkait dengan perbankan yang berada di luar wilayah Republik
            Indonesia; (viii) Pembayaran kartu; (ix) Penyelesaian kredit
            dan/atau; (x) dukungan terhadap organisasi terlarang atau dilarang
            oleh pemerintah.
          </p>
        </li>
        <li>
          <p lang="id-ID" align="justify">
            Pelanggan Aset Kripto menyatakan dan menjamin untuk tidak
            menggunakan Layanan Everest Kripto Indonesia terhadap tindakan
            praktek perjudian dan/atau kegiatan lain, mengenakan biaya masuk dan
            menjanjikan hadiah, termasuk namun tidak terbatas pada permainan
            kasino, perjudian dalam olahraga, usaha yang menfasilitasi perjudian
            dan cara undian.
          </p>
        </li>
        <li>
          <p lang="id-ID" align="justify">
            Pelanggan Aset Kripto menyatakan dan menjamin bahwa seluruh data,
            informasi dan dokumen yang diberikan Pelanggan Aset Kripto kepada
            Calon Pedagang Fisik Aset Kripto merupakan data, informasi dan
            dokumen yang sebenar-benarnya, sah, jujur, transparan, lengkap dan
            akurat. Pelanggan Aset Kripto menyatakan bersedia dituntut secara
            pidana maupun digugat secara perdata apabila Calon Pedagang Fisik
            Aset Kripto mengetahui atai memperoleh informasi dari pihak manapun
            bahwa data, informasi, dan dokumen yang diberikan Pelanggan Aset
            Kripto ternyata tidak benar/tidak sepenuhnya benar/palsu. Pelanggan
            Aset Kripto bersedia untuk melakukan pembaharuan data/informasi
            (profile update) apabila sewaktu-waktu diminta oleh Calon Pedagang
            Fisik Aset Kripto dan selanjutnya seluruh dokumen yang sudah
            diberikan menjadi sepenuhnya milik Calon Pedagang Fisik Aset Kripto
            .
          </p>
        </li>
        <li>
          <p lang="id-ID" align="justify">
            Calon Pedagang Fisik Aset Kripto telah memberikan keterangan dan
            penjelasan yang cukup mengenai layanan Calon Pedagang Fisik Aset
            Kripto yang akan dipergunakan Pelanggan Aset Kripto sesuai dengan
            ketentuan dan Syarat dan Ketentuan dan Pelanggan Aset Kripto telah
            mengerti dan memahami serta bersedia menanggung segala konsekuensi
            yang mungkin timbul Sehubungan dengan Pelanggan Aset Kripto layanan
            Calon Pedagang Fisik Aset Kripto termasuk manfaat, risiko dan
            biaya-biaya yang melekat pada layanan Calon Pedagang Fisik Aset
            Kripto .
          </p>
        </li>
        <li>
          <p lang="id-ID" align="justify">
            Pelanggan Aset Kripto dengan ini menyetujui dan memberi kuasa kepada
            Calon Pedagang Fisik Aset Kripto untuk menggunakan semua data,
            informasi dan keterangan yang diperoleh Calon Pedagang Fisik Aset
            Kripto mengenai Pelanggan Aset Kripto termasuk namun tidak terbatas
            pada sarana komunikasi pribadi Pelanggan Aset Kripto untuk segala
            keperluan lainnya sepanjang dimungkinkan dan diperkenankan oleh
            perundang-undangan yang berlaku, termasuk yang bertujuan untuk
            pemasaran produk-produk Calon Pedagang Fisik Aset Kripto ataupun
            pihak lain, yang berkerjasama dengan Calon Pedagang Fisik Aset
            Kripto . Pelanggan Aset Kripto menjamin dan menyetujui bahwa Calon
            Pedagang Fisik Aset Kripto tidak akan memberikan ganti rugi dan/atau
            pertanggungjawaban dalam bentuk apapun kepada Pelanggan Aset Kripto
            atau pihak manapun dari segala risiko, kerugian, tuntutan dan/atau
            tanggung jawab yang mungkin timbul dikemudian hari.
          </p>
        </li>
        <li>
          <p lang="id-ID" align="justify">
            Pelanggan Aset Kripto menyatakan dan menjamin bahwa transaksi yang
            dilakukan dengan menggunakan Layanan adalah transaksi yang tidak
            melanggar ketentuan peraturan perundang-undangan yang berlaku dan
            ketentuan mengenai penerimaan pelaksanaan transaksi sebagaimana
            diatur dalam Syarat dan Ketentuan. Dalam hal Calon Pedagang Fisik
            Aset Kripto menemukan indikasi pelaksanaan transaksi yang tidak
            sesuai dengan perundang-undangan yang berlaku dan/atau ketentuan
            mengenai penerimaan pelaksanaan transaksi yang diatur dalam Syarat
            dan Ketentuan, maka Calon Pedagang Fisik Aset Kripto mempunyai hak
            penuh untuk menutup Akun Calon Pedagang Fisik Aset Kripto Pelanggan
            Aset Kripto dengan menonaktifkan Layanan, dan Pelanggan Aset Kripto
            dengan ini setuju bahwa Calon Pedagang Fisik Aset Kripto dengan ini
            tidak akan memberikan ganti rugi dan/atau pertangungjawaban dalam
            bentuk apapun kepada Pelanggan Aset Kripto, atau pihak manapun atas
            segala klaim dan/atau tuntutan yang timbul Sehubungan dengan
            penonaktifan Layanan pada Pelanggan Aset Kripto
          </p>
        </li>
        <li>
          <p lang="id-ID" align="justify">
            Pelanggan Aset Kripto menyatakan dan menjamin bahwa risiko terhadap
            layanan, produk dan promosi pihak ketiga dengan Pelanggan Aset
            Kripto (apabila ada) ditangung oleh Pelanggan Aset Kripto, dan
            Pelanggan Aset Kripto menyatakan bahwa Calon Pedagang Fisik Aset
            Kripto tidak bertanggung jawab atas layanan dan kinerja layanan
            pihak ketiga.
          </p>
        </li>
        <li>
          <p lang="id-ID" align="justify">
            Pelanggan Aset Kripto dengan ini bertanggung jawab sepenuhnya dan
            setuju bahwa Calon Pedagang Fisik Aset Kripto tidak akan memberikan
            ganti rugi dan/atau pertangungjawaban dalam bentuk apapun kepada
            Pelanggan Aset Kripto atau pihak manapun atas segala kerugian
            dan/atau klaim dan/atau tuntutan yang timbul atau mungkin dialami
            oleh Calon Pedagang Fisik Aset Kripto sebagai akibat dari kelalaian
            atau kegagalan Pelanggan Aset Kripto dalam menjalankan transaksi.
          </p>
        </li>
        <li>
          <p lang="id-ID" align="justify">
            Pelanggan Aset Kripto dengan ini bertanggung jawab sepenuhnya dan
            setuju bahwa Calon Pedagang Fisik Aset Kripto tidak akan memberikan
            ganti rugi dan/atau pertanggungjawaban dalam bentuk apapun kepada
            Pelanggan Aset Kripto atau pihak manapun atas segala kerugian
            dan/atau klaim dan/atau tuntutan yang timbul atau mungkin dialami
            olehCalon Pedagang Fisik Aset Kripto sebagai akibat kelalaian atau
            kegagalan Pelanggan Aset Kripto dalam hal keamanan password atau
            kedensial dari Akun Calon Pedagang Fisik Aset Kripto Pelanggan Aset
            Kripto.
          </p>
        </li>
        <li>
          <p lang="id-ID" align="justify">
            Pelanggan Aset Kripto dengan ini memberikan jaminan kepada Calon
            Pedagang Fisik Aset Kripto bahwa Pelanggan Aset Kripto beserta
            dengan seluruh karyawan dan/atau pihak lain yang bekerja sama dengan
            Pelanggan Aset Kripto tidak akan memperbanyak dan/atau membuat,
            memberikan, menyewakan, menjual, memindahkan, mengalihkan, dan/atau
            mengalihkan- fungsikan layanan baik Sebagian atau seluruhnya kepada
            pihak lain dengan alasan apapun, termasuk namun tidak terbatas pada
            penyalahgunaan Situs Calon Pedagang Fisik Aset Kripto untuk
            melakukan transaksi selain dari yang telah ditentukan dalam Syarat
            dan Ketentuan dengan maksud apapun, termasuk namun tidak terbatas
            untuk kejahatan/penipuan/kecurangan. Apabila Pelanggan Aset Kripto
            melanggar ketentuan tersebut, maka Pelanggan Aset Kripto wajib
            bertanggung jawab atas segala kerugian, tuntutan dan/atau gugatan
            yang timbul akibat dari pelanggaran tersebut dan dengan ini setuju
            bahwa Calon Pedagang Fisik Aset Kripto tidak akan memberikan ganti
            rugi dan/atau pertanggungjawaban dalam hal apapun kepada Pelanggan
            Aset Kripto atau pihak manapun Calon Pedagang Fisik Aset Kripto
            tidak akan memberikan ganti rugi dan atau pertanggungjawaban dalam
            bentuk apapun kepada Pelanggan Aset Kripto atau pihak manapun atas
            segala klaim dan/atau tuntutan dan/atau gugatan yang timbul akibat
            pelanggaran tersebut.
          </p>
        </li>
      </ol>
      <ol type="I" start="6" class="text_h">
        <li>
          <p lang="en-ID" align="justify">
            <strong>KEWAJIBAN DAN TANGGUNG JAWAB</strong>
          </p>
        </li>
      </ol>
      <ol>
        <li>
          <p lang="id-ID" align="justify">
            Pelanggan Aset Kripto setuju untuk menanggung setiap risiko,
            kerugian atau akibat yang diderita Pelanggan Aset Kripto yang
            disebabkan oleh, antara lain:
          </p>
        </li>
      </ol>
      <ul>
        <li>
          <p lang="id-ID" align="justify">
            Kerusakan, keterlambatan, kehilangan atau kesalahan pengiriman
            perintah dan komunikasi, secara elektronik yang disebabkan oleh
            Pelanggan Aset Kripto;
          </p>
        </li>
        <li>
          <p lang="id-ID" align="justify">
            Laporan Akun Calon Pedagang Fisik Aset Kripto atau pemberitahuan
            layanan Calon Pedagang Fisik Aset Kripto yang dikirim kepada
            Pelanggan Aset Kripto diterima atau dibaca atau disalahgunakan oleh
            pihak lain yang tidak berwenang atas Akun Pelanggan Aset Kripto;
          </p>
        </li>
        <li>
          <p lang="id-ID" align="justify">
            Password Akun Calon Pedagang Fisik Aset Kripto diketahui oleh
            orang/pihak lain atas kesalahan Pelanggan Aset Kripto.
          </p>
        </li>
      </ul>
      <ol start="2">
        <li>
          <p lang="id-ID" align="justify">
            Pelanggan Aset Kripto memahami dan setuju bahwa Pelanggan Aset
            Kripto akan menggunakan Akun Calon Pedagang Fisik Aset Kripto dan
            Layanan untuk transaksi yang tidak bertentangan dengan ketentuan
            peraturan perundang-undangan dan/atau kebijakan internal Calon
            Pedagang Fisik Aset Kripto yang berlaku dan/atau peraturan-peraturan
            lainnya yang berlaku secara nasional maupun internasional yang
            terkait dengan pelaksanaan transaksi tersebut baik secara langsung
            maupun tidak langsung, dan Calon Pedagang Fisik Aset Kripto tidak
            akan memberikan ganti rugi dan/atau pertanggungjawaban dalam bentuk
            apapun kepada Pelanggan Aset Kripto atau pihak manapun atas segala
            klaim dan/atau tuntutan dan/atau kerugian yang timbul Sehubungan
            dengan Layanan oleh Pelanggan Aset Kripto untuk transaksi yang
            mencurigakan dan/atau transaksi yang dilarang yang dikatagorikan
            sebagai transaksi yang mencurigakan dan/atau transkasi yang dilarang
            oleh perundang-undangan dan/atau kebijakan internal Calon Pedagang
            Fisik Aset Kripto yang berlaku dan/atau peraturan-peraturan lain
            yang berlaku baik secara nasional maupun internasional yang terkait
            dengan kegiatan transaksi yang dilakukan oleh Pelanggan Aset Kripto
            secara langsung maupun tidak langsung.
          </p>
        </li>
        <li>
          <p lang="id-ID" align="justify">
            Dalam melakukan transaksi menggunakan sistem Calon Pedagang Fisik
            Aset Kripto , Pelanggan Aset Kripto mengerti dan menyetujui bahwa
            terdapat senksi-sanksi tertentu yang dikenakan oleh pemerintah,
            termasuk pemerintah Negara lain, dan/atau instansi berwenang lainnya
            terhadap beberapa negara, badan dan perorangan. Mengacu pada hal
            tersebut,Calon Pedagang Fisik Aset Kripto berhak untuk tidak
            melaksanakan/memproses transaksi yang merupakan pelanggaran terhadap
            ketentuan sanksi tersebut, dan instansi berwenang dapat mensyaratkan
            pengungkapan informasi terkait.Calon Pedagang Fisik Aset Kripto
            tidak bertanggung jawab apabila Calon Pedagang Fisik Aset Kripto
            atau pihak lain gagal atau menunda pelaksanaan transaksi, atau
            adanya pengungkapan informasi sebagai akibat pelanggaran langsung
            maupun tidak langsung atas ketentuan sanksi tersebut.
          </p>
        </li>
        <li>
          <p lang="id-ID" align="justify">
            Pelanggan Aset Kripto tidak diperbolehkan melakukan tidakan-tindakan
            yang dapat mengakibatkan kerugian bagi Calon Pedagang Fisik Aset
            Kripto dan/atau yang bertentangan dengan Syarat dan Ketentuan dan
            peraturan perundang-undangan yang berlaku
          </p>
        </li>
        <li>
          <p lang="id-ID" align="justify">
            Apabila Pelanggan Aset Kripto melanggar salah satu atau beberapa
            ketentuan ini, maka Pelanggan Aset Kripto akan bertanggung jawab
            sepenuhnya dan dengan ini setuju bahwa atas pelanggaran tersebut
            Calon Pedagang Fisik Aset Kripto berhak untuk membatasi fitur
            layananCalon Pedagang Fisik Aset Kripto hingga penutupan Akun Calon
            Pedagang Fisik Aset Kripto dan Pelanggan Aset Kripto mengetahui dan
            setuju bahwa Calon Pedagang Fisik Aset Kripto tidak akan memberikan
            ganti rugi dan/atau pertanggungjawaban dalam bentuk apapun kepada
            Pelanggan Aset Kripto atau pihak manapun atas segala risiko atau
            akibat yang timbul atas pembatasan Layanan dan penutupan Akun Calon
            Pedagang Fisik Aset Kripto tersebut. Pelanggan Aset Kripto
            selanjutnya berkewajiban untuk membayar ganti rugi kepada Calon
            Pedagang Fisik Aset Kripto sebesar kerugian yang mungkin dialami
            Calon Pedagang Fisik Aset Kripto atas pelanggaran tersebut.
            Pelanggan Aset Kripto dengan ini memberikan kuasa kepada Calon
            Pedagang Fisik Aset Kripto untuk melakukan pendebetan Akun Pelanggan
            Aset Kripto untuk membayar ganti rugi tersebut. Dalam hal Dana pada
            Akun Pelanggan Aset Kripto tidak tersedia dan/atau tidak mencukupi,
            maka Pelanggan Aset Kripto wajib mengembalikan seluruh Dana tersebut
            secara pemindahbukuan antar bank kepada Calon Pedagang Fisik Aset
            Kripto dalam waktu selambat-lambatnya 7 (tujuh) hari kerja sejak
            Calon Pedagang Fisik Aset Kripto mengajukan klaim dimaksud.
          </p>
        </li>
      </ol>
      <ol type="I" start="7" class="text_h">
        <li>
          <p lang="en-ID" align="justify">
            <strong>PENGKINIAN DATA PELANGGAN</strong>
          </p>
        </li>
      </ol>
      <p lang="en-ID" align="justify">
        Pengkinian terkait data/infomasi terkait dengan APU &amp; PPT sebagai
        berikut:
      </p>
      <ol type="a">
        <li>
          <p lang="en-ID" align="justify">
            Melakukan analisa terhadap transaksi pelanggan.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Melakukan pengkinian data, informasi dan/atau dokumen yang
            ditentukan oleh Perusahaan.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Melakukan review terhadap profil dan transaksi pelanggan yang
            termasuk tingkat risiko menengah dan/atau rencah.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Dalam hal melakukan pengkinian data, Perusahaan wajib untuk:
          </p>
        </li>
      </ol>
      <p lang="en-ID" align="justify">
        ● melakukan pemantauan terhadap informasi dan dokumen Nasabah.
      </p>
      <p lang="en-ID" align="justify">● menyusun rencana pengkinian data.</p>
      <p lang="en-ID" align="justify">● menyusun realisasi pengkinian data.</p>
      <ol type="I" start="8" class="text_h">
        <li>
          <p lang="en-ID" align="justify">
            <strong>TATA CARA KEGIATAN TRANSAKSI</strong>
          </p>
        </li>
      </ol>
      <ol type="A" class="text_h">
        <li>
          <p lang="en-ID" align="justify">
            <strong>DEPOSIT</strong>
          </p>
        </li>
      </ol>
      <ol type="a" class="text_h">
        <li>
          <h2 lang="id-ID" align="justify">Deposit Rupiah</h2>
        </li>
      </ol>
      <ol>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan mengisi formulir deposit Rupiah dengan jumlah yang
            diinginkan.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Setelah disimpan, aplikasi akan mengirimkan instruksi deposit,
            seperti; nomor akun, nama akun dan nama bank.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">Pelanggan upload bukti transfer.</p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pada back office, Admin akan melakukan persetujuan atau menolak
            deposit tersebut berdasarkan bukti yang diupload.
          </p>
          <ol type="a" class="text_h">
            <li>
              <p lang="en-ID" align="justify">
                Jika disetujui saldo pelanggan dalam Rupiah akan bertambah.
              </p>
            </li>
            <li>
              <p lang="en-ID" align="justify">
                Jika tidak disetujui, saldo Rupiah tidak bertambah dan pelanggan
                wajib mengupload kan yang sesuai.
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <ol type="a" start="2" class="text_h">
        <li>
          <h2 lang="id-ID" align="justify">
            <a name="_heading=h.rckjui15uas4"></a>Deposit Kripto
          </h2>
        </li>
      </ol>
      <ol>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan mengunjungi halaman deposit sesuai dengan kripto yang
            diinginkan.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan mencatat alamat deposit kripto dengan benar.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan melakukan deposit kripto melalui <em>wallet</em> sendiri
            atau transfer dari <em>wallet</em> <em>exchange</em>
            lainnya.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Aplikasi akan secara otomatis memasukkan ke dalam database jika
            menerima deposit pada alamat yang ada pada pelanggan.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Saldo pelanggan akan bertambah secara otomatis sesuai kripto yang di
            depositkan.
          </p>
        </li>
      </ol>

      <h2 lang="id-ID" align="justify">
        <br />
      </h2>
      <ol type="A" start="2" class="text_h">
        <li>
          <h2 lang="id-ID" align="justify">
            <a name="_heading=h.fpu6sg8qozs2"></a>Transaksi Jual Dan Beli
          </h2>
        </li>
      </ol>
      <ol>
        <li>
          <p lang="en-ID" align="justify">
            Untuk melalukan transaksi jual dan beli, pelanggan klik menu
            “Exchange / Pertukaran”, lalu klik aset kripto yang ingin
            ditransaksikan.
          </p>
        </li>
      </ol>
      <ol start="2">
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan melakukan transaksi beli ketika order close akan terlihat
            notifikasi pembelian.
          </p>
        </li>
      </ol>
      <ol start="3">
        <li>
          <p lang="en-ID" align="justify">
            Dalam Laman Website Penjual pun akan ada Notifikasi atas order close
            tersebut.
          </p>
        </li>
      </ol>
      <ol start="4">
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan juga dapat melihat History Transaksi yang dilakukan,
            secara real-time.
          </p>
        </li>
      </ol>
      <ol start="5">
        <li>
          <p lang="en-ID" align="justify">
            Jika pelanggan ingin melakukan transaksi Jual, tinggal klik saja
            transaksi yang ingin dijual, lalu akan muncul notifikasi seperti
            pada saat transaksi pembelian.
          </p>
        </li>
      </ol>
      <ol type="A" start="3" class="text_h">
        <li>
          <p lang="en-ID">
            <strong>Limit Order &amp; Market Order</strong>
          </p>
        </li>
      </ol>
      <ul>
        <li>
          <h3 lang="id-ID" align="justify">Limit Order</h3>
        </li>
      </ul>
      <p lang="en-ID" align="justify">
        Limit Order adalah pelanggan yang meletakkan order beli atau jual tetapi
        menunggu untuk terpenuhi. Order jual dan order beli akan tertera pada
        orderbook.
      </p>
      <ol>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan masuk ke halaman pertukaran
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan memilih pasar yang diinginkan
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan menginputkan beli atau jual dan kuantitas yang diinginkan.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan menentukan harga jual atau harga beli
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan menunggu proses jual dan beli sampai ada pelanggan lain
            yang mengambil.
          </p>
        </li>
      </ol>
      <ul>
        <li>
          <h3 lang="id-ID" align="justify">Market Order</h3>
        </li>
      </ul>
      <p lang="en-ID" align="justify">
        Market Order adalah pelanggan yang langsung meletakkan jual atau beli
        tepat pada orderbook sehingga jual/beli langsung terpenuhi.
      </p>
      <ol>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan masuk ke halaman pertukaran
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan memilih pasar yang diinginkan
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan menginputkan beli atau jual dan kuantitas yang diinginkan.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan langsung mengambil order yang tertera di orderbook.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Jika harga terpenuhi dan kuantitas cukup, maka saldo akan bertambah.
          </p>
        </li>
      </ol>
      <ol type="A" start="4" class="text_h">
        <li>
          <h1 lang="id-ID" align="justify">Biaya transaksi</h1>
        </li>
      </ol>
      <p lang="en-ID" align="justify">
        Biaya transaksi di bursa kripto akan dijelaskan sebagai berikut :
      </p>
      <ul>
        <li>
          <p lang="en-ID" align="justify">Deposit IDR</p>
        </li>
      </ul>
      <p lang="en-ID" align="justify">
        Untuk melakukan deposit Rupiah (IDR) minimal deposit : Rp. 10.000,00
      </p>
      <ul>
        <li>
          <p lang="en-ID" align="justify">Pembelian</p>
        </li>
      </ul>
      <p lang="en-ID" align="justify">
        Anda dapat mulai melakukan pembelian dengan minimal pembelian : Rp.
        10.000,00
      </p>
      <ul>
        <li>
          <p lang="en-ID" align="justify">
            Biaya Deposit : Virtual Account (VA) dikenakan 3000 - 4500, dan 0
            jika tidak menggunakan VA
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Biaya withdraw Rupiah berkisar antara 5.500 atau 1% dari total
            withdraw
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Biaya withdraw aset digital disesuaikan dan berbeda-beda antara satu
            dan lainnya. Pelanggan diharapkan membaca dengan teliti sebelum
            melakukan withdraw.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Biaya perdangan : 0.3 % sampai dengan 0.5%
          </p>
        </li>
      </ul>
      <ol type="A" start="5" class="text_h">
        <li>
          <h1 lang="id-ID" align="justify">
            Ketentuan Penarikan dan Pengiriman Transaksi
          </h1>
        </li>
      </ol>
      <ol>
        <li>
          <p lang="en-ID" align="justify">
            Everest Kripto Indonesia berhak untuk menunda dan/atau menolak suatu
            transaksi jika bursa kripto mengetahui atau sedang mempertimbangkan,
            menduga bahwa aktivitas penipuan dan/atau kejahatan telah dan/atau
            akan dilaku kan.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan setuju dan mengakui bahwa sejauh diizinkan oleh ketentuan
            hukum yang berlaku, Everest Kripto Indonesia berhak menolak untuk
            memproses transaksi apa pun.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan mengakui bahwa bursa kripto tunduk pada undang-undang
            tentang kejahatan keuangan, termasuk tetapi tidak terbatas pada,
            Undang-Undang Pemberantasan Korupsi dan Undang-Undang tentang
            Pencucian Uang yang berlaku di Indonesia dan internasional, setiap
            undang-undang yang berlaku di Indonesia dan kebijakan internal bursa
            kripto. Untuk tujuan ini, Pelanggan dengan ini setuju bahwa semua
            informasi yang diminta oleh bursa kripto untuk memenuhi hukum dan
            peraturan termasuk tetapi tidak terbatas pada nama, alamat, usia,
            jenis kelamin, informasi identitas pribadi, pendapatan, pekerjaan,
            properti, hutang, sumber kekayaan, tujuan pembukaan rekening, tujuan
            investasi, rencana keuangan atau informasi keuangan lain yang
            relevan dari pelanggan. Jika diperlukan oleh bursa kripto, pelanggan
            juga setuju untuk menggunakan data terbaru tentang informasi
            tersebut kepada bursa kripto.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Bursa kripto berkewajiban untuk mematuhi undang-undang, peraturan
            dan permintaan untuk lembaga masyarakat dan lembaga pemerintah di
            yurisdiksi yang berbeda yang berkaitan dengan pencegahan pembiayaan
            untuk, antara lain, teroris dan pihak yang terkena sanksi. Hal ini
            dapat menyebabkan bursa kripto untuk mencegat dan menyelidiki semua
            perintah pembayaran dan informasi atau komunikasi lain yang dikirim
            ke atau oleh pelanggan, atau atas nama pelanggan melalui sistem
            bursa kripto. Proses ini juga dapat melibatkan bursa kripto untuk
            melakukan penyelidikan lebih lanjut untuk menentukan apakah nama
            yang muncul di semua transaksi yang dilakukan atau akan dilakukan
            oleh pelanggan melalui akun mereka adalah nama teroris.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Bursa kripto tidak akan bertanggung jawab atas segala kerusakan
            (baik langsung, dan termasuk namun tidak terbatas pada kehilangan
            keuntungan atau bunga) atau kerusakan yang diderita oleh pihak
            manapun yang timbul dari keterlambatan atau kelalaian bursa kripto
            untuk memproses semua pembayaran perintah atau informasi atau
            komunikasi lain atau untuk melakukan kewajiban lainnya, yang
            mengakibatkan seluruh atau sebagian dari tindakan yang diambil
            berdasarkan ketentuan angka 4 penolakan dan Penangguhan transaksi
            ini.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Bursa kripto berwenang untuk memantau akun pelanggan untuk mencegah
            kejahatan keuangan.
          </p>
        </li>
      </ol>
      <ol type="A" start="6" class="text_h">
        <li>
          <h2 lang="id-ID" align="justify">
            <a name="_heading=h.17dp8vu"></a
            ><a name="_heading=h.2s8eyo1"></a>Transaksi Mencurigakan
          </h2>
        </li>
      </ol>
      <h2 lang="id-ID" align="justify">
        <br />
      </h2>
      <ol>
        <li>
          <p lang="en-ID" align="justify">
            Dalam hal terjadi transaksi mencurigakan yang dilakukan melalui
            fasilitas bursa kripto, maka bursa kripto berhak
            menghentikan/menonaktifkan akun bursa kripto pada pelanggan dan
            memblokir dana transaksi serta menunda transaksi kepada pelanggan,
            sampai dengan pemberitahuan dari bursa kripto.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Dalam hal terjadi transaksi mencurigakan dan/atau transaksi yang
            melebihi batas yang ditetapkan oleh volume transaksi bursa kripto
            terhadap pelanggan dengan alasan apapun, maka bursa kripto berhak
            setiap saat menunda pengkreditan dana ke akun pelanggan bursa kripto
            dan/atau pemblokiran pelanggan e-wallet sampai proses investigasi
            selesai dalam jangka waktu yang ditentukan oleh bursa kripto.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Apabila terbukti transaksi pada angka 1 dan 2 tersebut di atas
            mengenai transaksi mencurigakan merupakan transaksi yang melanggar
            SKU dan/atau peraturan perundang-undangan yang berlaku, maka
            pelanggan dengan ini menggunakan kuasa kepada bursa kripto untuk
            mendebet aset kripto di e-wallet pelanggan untuk mengganti kerugian
            bursa kripto yang timbul dari transaksi tersebut, tanpa mengurangi
            hak bursa kripto untuk menuntut ganti rugi atas semua kerugian yang
            timbul dari transaksi tersebut dan pelanggan dengan ini setuju bahwa
            bursa kripto tidak berkewajiban untuk mengembalikan dana yang
            tertunda tanpa pengecualian oleh bursa kripto atau dana yang
            diblokir sebagaimana dimaksud pada angka 2 Ketentuan mengenai
            transaksi mencurigakan ini.
          </p>
        </li>
      </ol>
      <ol type="A" start="7" class="text_h">
        <li>
          <h1 lang="id-ID" align="justify">
            <a name="_heading=h.3rdcrjn"></a>Batas Penarikan
          </h1>
        </li>
      </ol>
      <p lang="en-ID" align="justify">
        Pelanggan dengan ini setuju untuk mematuhi baik batas penarikan aset
        kripto maupun uang yang berlaku pada akun pelanggan, dan bursa kripto
        yang dalam hal ini menerapkan prinsip Anti Money Laundering (AML) sesuai
        dengan peraturan Pemerintah Republik Indonesia diberi kuasa dan hak
        untuk tidak memproses transaksi yang telah melampaui batas penarikan
        harian sesuai level tiap levelnya.
      </p>
      <ol type="A" start="8" class="text_h">
        <li>
          <p lang="en-ID" align="justify">
            <strong>Formula Perhitungan Transaksi</strong>
          </p>
        </li>
      </ol>
      <ol type="a">
        <li>
          <p lang="en-ID" align="justify">
            Pembelian Aset Kripto dapat dilakukan menggunakan Token/ Koin
            berdasarkan jenis kontrak yang akan diperdagangkan;
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Mata Uang yang dipakai untuk melakukan transak si Aset Kripto adalah
            Rupiah;
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Rumus perhitungan total nilai aset beli:
          </p>
          <p lang="en-ID" align="center">
            <strong>TOTAL NILAI ASET BELI</strong>
            <span style="font-size: 16px; font-weight: bold"
              >= Harga Beli x Jumlah Koin</span
            >
          </p>
        </li>
      </ol>
      <ol type="a" start="4">
        <li>
          <p lang="en-ID" align="justify">
            Rumus perhitungan total nilai aset jual:
          </p>
          <p lang="en-ID" align="center">
            <strong>TOTAL NILAI ASET</strong>
            <span style="font-size: 16px; font-weight: bold"
              >= Harga Jual x Jumlah Koin</span
            >
          </p>
        </li>
      </ol>
      <ol type="a" start="5">
        <li>
          <p lang="en-ID" align="justify">Formula perhitungan untung / rugi:</p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Formula perhitungan untung rugi secara <em>realtime</em>dapat
            dilakukan dengan perhitungan sebagai berikut:
          </p>
        </li>
      </ol>
      <p lang="en-ID" align="center">
        <strong>BELI =</strong>
        <span style="font-size: 16px; font-weight: bold"
          >Harga Beli – Harga Pasar</span
        >
      </p>
      <p lang="en-ID" align="center">
        <strong>JUAL =</strong
        ><span style="font-size: 16px; font-weight: bold"
          >Harga Pasar – Harga Jual</span
        >
      </p>
      <br />
      <ol type="a" start="7">
        <li>
          <p lang="en-ID" align="justify">Formula perhitungan saldo (equity)</p>
          <p lang="en-ID" align="center">
            <strong>SALDO (EQUITY) =</strong>
            <span style="font-size: 16px; font-weight: bold"
              >Total Saldo Kripto + Total Saldo Dana</span
            >
          </p>
        </li>
      </ol>
      <ol type="A" start="9" class="text_h">
        <li>
          <p lang="en-ID" align="justify">
            <strong>Ketentuan Pelaksanaan Transaksi</strong>
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">TRANSAKSI JUAL/BELI ASET KRIPTO</p>
        </li>
      </ol>
      <ol>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan Aset Kripto dapat melakukan transaksi apabila yang
            bersangkutan memiliki kecukupan dana dan/atau saldo Aset Kripto;
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pedagang Fisik Aset Kripto dilarang memfasilitasi transaksi apabila
            Pelanggan tidak memiliki kecukupan dana dan/atau saldo Aset Kripto;
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan dapat melaklukan transaksi jual/beli di dalam sistem
            transaksi Calon Pedagang Aset Kripto
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan dapat menggunakan 2 jenis order yang disediakan di dalam
            sistem transaksi, yaitu <em>Market order</em> dan
            <em> Limit Order </em>
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Apabila pelanggan menggunakan <em>Market Order</em> maka, transaksi
            nasabah akan terjadi di harga terbaik yang tersedia pada saat itu
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Apabila pelanggan menggunakan <em>limit order</em> maka nasabah akan
            mendapatkan harga sesuai dengan harga yang diinginkan yang dipasang
            pada sistem perdagangan
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan dapat melihat Riwayat transaksinya ataupun jumlah
            <em> open order</em> di dalam sistem perdagangan
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan dapat melakukan pembatalan atas <em>limit order</em>
            yang dipasangnya selama order belum terisi (<em>filled</em>)
          </p>
        </li>
      </ol>
      <ol type="A" start="10" class="text_h">
        <li>
          <p lang="en-ID" align="justify">
            <strong>Pembatalan Transaksi</strong>
          </p>
        </li>
      </ol>
      <ol type="a">
        <li>
          <p lang="en-ID" align="justify">
            Everest Kripto Indonesia berhak untuk melakukan penundaan dan/atau
            pembatalan transaksi berdasarkan pertimbangan dan dugaan bahwa
            terjadi kesalahan harga, kegiatan penipuan dan/atau aksi kejahatan
            telah dan/atau akan dilakukan;
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan Aset Kripto setuju dan mengakui bahwa sepanjang
            diperbolehkan oleh ketentuan Hukum yang berlaku, BURSA KRIPTO
            INDONESIA berhak menolak untuk memproses segala transaksi;
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Everest Kripto Indonesia berwenang untuk melakukan pemantauan atas
            Akun Pelanggan Aset Kripto dalam rangka pencegahan kejahatan
            keuangan;
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan Aset Kripto mengerti, memahami dan setuju bahwa terhadap
            setiap transaksi yang telah dilakukan melalui Calon Pedagang Fisik
            Aset Kripto bersifat final dan tidak dapat dilakukan pembatalan oleh
            Pelanggan Aset Kripto;
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pedagang Fisik Aset Kripto tidak akan bertanggung jawab untuk setiap
            kerugian (baik secara langsung dan termasuk namun tidak terbatas
            pada kehilangan keuntungan atau bunga) atau kerugian yang diderita
            oleh pihak manapun yang timbul dari segala penundaan atau kelalaian
            dari Calon Pedagang Fisik Aset Kripto untuk memproses segala
            perintah pembayaran tersebut atau informasi atau komunikasi lainnya
            atau untuk melaksanakan segala kewajiban lainnya, yang disebabkan
            secara keseluruhan atau sebagian oleh segala tindakan yang diambil
            berdasarkan ketentuan Penolakan dan Penundaan Transaksi ini;
          </p>
        </li>
      </ol>
      <ol type="I" start="9" class="text_h">
        <li>
          <p lang="en-ID" align="justify">
            <strong>WAKTU PERDAGANGAN DAN KUANTITAS MINIMUM</strong>
          </p>
        </li>
      </ol>
      <ul>
        <li>
          <p lang="en-ID" align="justify">
            Waktu Perdagangan dalam keadaan normal, Pelanggan Aset Kripto dapat
            melakukan transaksi setiap saat (24 jam x 7 hari)
          </p>
        </li>
      </ul>
      <o type="a">
        <ol>
          <li>
            <p lang="en-ID" align="justify">Hari Perdagangan: Senin – Minggu</p>
          </li>
          <li>
            <p lang="en-ID" align="justify">Jam Perdagangan: 00.00 – 24.00</p>
          </li>
        </ol>
      </o>
      <ul>
        <li>
          <p lang="en-ID" align="justify">
            Kuantitas Minimum yang dapat diperdagangkan adalah sebesar
            0,00000001 coin
          </p>
        </li>
      </ul>

      <ol type="I" start="12" class="text_h">
        <li>
          <p lang="en-ID" align="justify">
            <strong>KEAMANAN TRANSAKSI</strong>
          </p>
        </li>
      </ol>
      <p lang="en-ID" align="justify">
        Untuk keamanan dalam bertransaksi, Pelanggan dapat melakukan perubahan
        Password secara berkala. Pelanggan juga wajib menjaga keamanan alat
        elektronik agar terhindar dari serangan cyber dan membahayakan akun
        pelanggan. Pelanggan dapat dengan segera memberitahukan kepada Calon
        Pedagang Aset Kripto untuk segera melakukan pembekuan terhadap akunnya
        apabila pelanggan mengalami serangan cyber.
      </p>
      <p lang="en-ID" align="justify">
        Dalam hal penarikan dana rupiah, Calon Pedagang Fisik Aset Kripto dapat
        memberikan keamanan dikarenakan dana tersebut wajib dikirimkan kepada
        rekening atas nama nasabah, namun untuk penarikan Aset Kripto, tidak
        dapat dilakukan pencegahan dalam hal terjadi serangan Cyber. Segala
        resiko yang muncul dikarenakan keterlambatan informasi dari Pelanggan
        bukan merupakan tanggung jawab Calon Pedagang Aset Kripto.
      </p>
      <ol type="I" start="13" class="text_h">
        <li>
          <p lang="en-ID" align="justify">
            <strong>DAFTAR ASET KRIPTO YANG DAPAT DIPERDAGANGKAN</strong>
          </p>
        </li>
      </ol>
      <p lang="en-ID" align="justify">
        Sesuai yang tertera dalam Peraturan Badan Pengawas Perdagangan Berjangka
        Komoditi Nomor 4 Tahun 2023, Sejumlah 501 Jenis Aset Kripto.
      </p>
      <ol type="I" start="14" class="text_h">
        <li>
          <p lang="en-ID" align="justify">
            <strong>
              LAYANAN PENGADUAN PELANGGAN ASET KRIPTO DAN MEKANISME PENYELESAIAN
              PERSELISIHAN PELANGGAN ASET KRIPTO
            </strong>
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan dapat melakukan pengaduan dengan cara:
          </p>
          <ol type="i">
            <li style="font-size: 14px; font-weight: 400">
              <p lang="en-ID" align="justify">
                Mengirimkan surat/ email yang memuat jenis permasalahan yang
                dihadapi dan bukti terjadinya permasalahan dalam transaksi
                kepada Calon Pedagang Fisik Aset Kripto
              </p>
            </li>
            <li style="font-size: 14px; font-weight: 400">
              <p lang="en-ID" align="justify">
                Calon Pedagang Fisik Aset Kripto akan memproses keluhan nasabah
                dan memberikan penyelesaian yang dianggap paling tepat untuk
                kepentingan bersama.
              </p>
            </li>
            <li style="font-size: 14px; font-weight: 400">
              <p lang="en-ID" align="justify">
                Dalam hal terjadi perselisihan antara para pihak dalam
                penyelenggaraan Pasar Fisik Aset Kripto, terlebih dahulu
                dilakukan penyelesaian dengan cara musyawarah untuk mencapai
                mufakat antara para pihak;
              </p>
            </li>
            <li style="font-size: 14px; font-weight: 400">
              <p lang="en-ID" align="justify">
                Dalam hal tidak tercapai mufakat sebagaimana yang dimaksud
                diatas, para pihak dalam Perdagangan Pasar Fisik Aset Kripto
                yang berselisih dapat menyelesaikan melalui Badan Arbitrase
                Perdagangan Komoditi (BAKTI) atau Pengadilan Negeri Jakarta
                Selatan.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            <strong>KEADAAN KAHAR</strong>
            <em>
              <strong>(FORCE MAJOUR)</strong>
            </em>
          </p>
        </li>
      </ol>
      <p lang="en-ID" align="justify">
        Keadaan Kahar atau <em>Force Majeure</em> adalah kejadian-kejadian yang
        terjadi di luar kemampuan dan kekuasaan Calon Pedagang Fisik Aset Kripto
        sehingga mempengaruhi pelaksanaan transaksi antara lain namun tidak
        terbatas pada:
      </p>
      <ul>
        <li>
          <p lang="en-ID" align="justify">
            Gempa bumi, angin topan, banjir, tanah longsor, gunung meletus dan
            bencana alam lainnya;
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Perang, demonstrasi, huru-hara, terorisme, sabotase, embargo, dan
            pemogokan massal;
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Kebijakan ekonomi dari Pemerintah yang mempengaruhi secara langsung;
          </p>
        </li>
      </ul>
      <p lang="en-ID" align="justify">
        Sepanjang Calon Pedagang Fisik Aset Kripto telah melaksanakan segala
        kewajibannya sesuai dengan peraturan perundang­ undangan yang berlaku
        sehubungan dengan terjadinya <em>Force Majeure</em> tersebut, maka Calon
        Pedagang Fisik Aset Kripto tidak akan memberikan ganti rugi dan atau
        pertanggungjawaban dalam bentuk apapun kepada Pelanggan Aset Kripto atau
        pihak lain manapun atas segala risiko, tanggungjawab dan tuntutan apapun
        yang mungkin timbul sehubungan dengan keterlambatan maupun tidak dapat
        dilaksanakannya kewajiban akibat
        <em>Force Majeure</em>
      </p>
      <ol type="I" start="16" class="text_h">
        <li>
          <p lang="en-ID" align="justify">
            <strong>
              PENERAPAN PROGRAM ANTI PENCUCIAN UANG DAN PENCEGAHAN PENDANAAN
              TERORISME (APU-PPT) SERTA PROLIFERASI SENJATA PEMUSNAH MASSAL
            </strong>
          </p>
        </li>
      </ol>
      <p lang="en-ID" align="justify">
        Perusahaan mendukung penuh terkait penerapan program APU-PPT khususnya
        di dalam perdagangan Aset Kripto, perusahaan menyadari akan bahaya dari
        penyalah-gunaan transaksi aset kripto yang dijadikan sebagai sarana
        pencucian uang dan/atau pendanaan terorime yang akan memberikan dampak
        negatif bagi negara pada umumnya dan bagi perusahaan secara khusus
      </p>
      <p lang="en-ID" align="justify">
        Selain itu, semua Pelanggan Aset Kripto baru diwajibkan untuk melakukan
        proses KYC dan pemeriksaan informasi dengan menerapkan CDD atau EDD yang
        telah disesuaikan dengan aturan yang berlaku guna menghindari Sistem
        transaksi Perusahaan digunakan oleh individu dengan risiko tinggi. Semua
        verifikasi dilakukan oleh divisi operation dengan SOP yang jelas dan
        diaudit dari waktu ke waktu.
      </p>
      <ol type="I" start="17" class="text_h">
        <li>
          <p lang="en-ID" align="justify">
            <strong>PEMBERITAHUAN</strong>
          </p>
        </li>
      </ol>
      <p lang="en-ID" align="justify">
        Calon Pedagang Aset Kripto akan memberitahukan kepada Pelanggan apabila
        melakukan hal-hal dibawah ini:
      </p>
      <ol type="a">
        <li>
          <p lang="en-ID" align="justify">
            Minimal 1 hari sebelumnya apabila akan melakukan Maintanance sistem
            perdagangan
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Minimal 30 hari sebelumnya apabila akan melakukan delisting Aset
            Kripto yang bukan disebabkan oleh Kelalaian penerbit Aset Kripto.
            Delisting Aset Kripto yang disebabkan oleh kelalaian atau
            kesengajaan dari pihak penerbit Aset Kripto yang dianggap
            membahayakan pelanggan dapat dilakukan sewaktu-waktu tanpa batas
            waktu 30 hari demi kepentingan Pelanggan.
          </p>
        </li>
      </ol>
      <div>
        <br />
      </div>
    </div>
    <div>
      <ol type="I" class="text_h">
        <li>
          <p lang="en-ID" align="justify">
            <strong>KERAHASIAAN</strong>
          </p>
        </li>
      </ol>
      <p lang="en-ID" align="justify">
        Setiap <em>account</em>memiliki Nomor <em>Login</em>dan
        <em> Password </em>
        yang sifatnya <span>SANGAT PRIBADI dan RAHASIA.</span>Pelanggan
        <span>W A J I B</span>untuk menjaga kerahasiaan nomor akun, email dan
        password akun.
      </p>
      <ol type="I" start="2" class="text_h">
        <li>
          <p lang="en-ID" align="justify">
            <strong>SYARAT DAN KETENTUAN</strong>
          </p>
        </li>
      </ol>
      <ol type="a">
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan dengan ini menyatakan telah membaca, mengerti, dan
            menyetujui seluruh hal, syarat dan ketentuan yang diatur dalam
            dokumen ini.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan dengan ini menyatakan telah mengerti resiko yang terdapat
            dalam transaksi Aset Kripto.
          </p>
        </li>
        <li>
          <p lang="en-ID" align="justify">
            Pelanggan dengan ini mengerti bahwa kerahasian Akun dan Passweord
            akun sangat penting demi keamanan akun Pelanggan.
          </p>
        </li>
      </ol>

      <div class="disclaimer">
        <p lang="en-ID" align="center">
          <strong>
            PT Everest Kripto Indonesia tidak pernah memberikan wewenang kepada
            pihak manapun untuk meminta
          </strong>
          <em><strong>password</strong></em
          ><strong>akun transaksi</strong><em><strong></strong></em>
          <strong>Anda dengan alasan apapun.</strong>
        </p>
        <p lang="en-ID" align="center">
          <strong>
            Perusahaan tidak bertanggung jawab apabila terjadi penyalahgunaan
            Nomor
          </strong>
          <em><strong>Login</strong></em
          ><strong>dan</strong><em><strong>password</strong></em>
          <strong>akibat kelalaian Pelanggan sendiri.</strong>
        </p>
        <br />
        <p lang="en-ID" align="center">
          <strong>DISCLAIMER</strong>
        </p>
        <p lang="en-ID" align="center">
          Perdagangan ASET KRIPTO memiliki peluang dan resiko yang tinggi.
          Apabila Anda hendak berinvestasi dalam Perdagangan ASET KRIPTO, Anda
          terlebih dahulu harus mengerti dan memahami kegiatan Perdagangan ASET
          KRIPTO serta isi dari Perjanjian dan Peraturan Transaksi.
        </p>
        <br />
        <p lang="en-ID" align="justify">
          Dengan ini, Pelanggan telah membaca, mengetahui, mengerti dengan baik
          dan benar serta menyetujui semua Peraturan dan Ketentuan
          <em> (Trading Rules &amp; Regulations) </em>
          ini.
        </p>
        <p lang="en-ID" align="justify">
          <span style="font-weight: bold">Catatan: </span>PT EVEREST KRIPTO
          INDONESIA memiliki hak penuh untuk merubah Peraturan dan Ketentuan
          Transaksi ini sewaktu – waktu tanpa ada pemberitahuan dahulu kepada
          Pelanggan.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// node_modules

// applibs

export default {
  components: {},
  data() {
    return {
      web_domain: "bitbromo.com",
    };
  },
};
</script>

<style scoped>
.text_h {
  list-style-type: none;
  margin-left: -20px;
  padding-top: 20px;
}

h2 {
  font-size: 2.5rem;
}
.txt_p,
.text_h,
h1,
h2,
strong {
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
}

li,
p {
  font-size: 16px;
}

.ctr_trm {
  max-width: 932px;
  padding: 0 20px;
  z-index: 995;
}

.head_txt {
  font-size: 28px;
  margin-left: -20px;
}

.disclaimer {
  padding: 20px;
  margin-top: 40px;
  background-color: #fff3cd;
  color: gray;
}

@media (max-width: 800px) {
  h5.title {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .txt_p,
  .text_h,
  .head_txt,
  h1,
  h2,
  strong {
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
  }

  li,
  p {
    font-size: 14px;
  }
}
</style>
