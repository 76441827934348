<template>
  <div style="padding: 25px;">
    <p>
      <span style="font-size: 12pt">
        <span style="font-family: Roboto, sans-serif"
          ><span style="color: #000000"
            ><strong>SURAT PERJANJIAN PELANGGAN ASET KRIPTO</strong></span
          ></span
        ></span
      >
    </p>

    <p>&nbsp;</p>
    <p>
      <span style="font-size: 12pt">
        <span style="font-family: Roboto, sans-serif"
          ><span style="color: #000000">Pihak Pertama:</span></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 12pt">
        <span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            ><strong>PT EVEREST KRIPTO INDONESIA</strong></span
          ></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000">Alamat: </span></span
        ></span
      ><span style="font-size: 11pt">
        <span style="font-family: Arial, sans-serif"
          ><span style="color: #000000"
            >Menara Rajawali Lt.11
            Jl Dr Ide Anak Augn Gede Agung lot 5.1 Kawasan Mega Kuningan, Jakarta 12950, Indonesia</span
          ></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 11pt"
        ><span style="font-family: Arial, sans-serif">
          <span style="color: #000000"
            >Nomor Tanda Daftar: menunggu-menunggu-menunggu</span
          ></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            >Website: www.bitbromo.com</span
          ></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            >Email dan Layanan Pelanggan: info@bitbromo.com</span
          ></span
        ></span
      >
    </p>

    <p>&nbsp;</p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000">Pihak Kedua:</span></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            ><strong>{{ user.orang_name }} </strong></span
          ></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            >ID: {{ user.orang_nik }}</span
          ></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            >Pekerjaan: {{ user.orang_profession }}</span
          ></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            >Alamat: {{ user.orang_address }}</span
          ></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            >Alamat Email: {{ user.orang_email }}</span
          ></span
        ></span
      >
    </p>

    <p>
      <br />
      &nbsp;
    </p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            ><strong>PASAL 1 - PENDAHULUAN</strong></span
          ></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 12pt">
        <span style="font-family: Roboto, sans-serif">
          <span style="color: #000000">
            1.1. Pihak Pertama adalah sebuah perusahaan yang bergerak dalam
            perdagangan Aset Kripto di bawah nama &quot;PT Everest Kripto
            Indonesia&quot;.
          </span>
        </span>
      </span>
    </p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            >1.2. Pihak Kedua adalah individu yang berusia 17 tahun atau lebih,
            memiliki Kartu Tanda Penduduk (KTP) atau dokumen identifikasi resmi
            yang diterbitkan oleh Negara asalnya (KITAP atau KITAS bagi warga
            negara asing), dan memiliki dana atau Aset Kripto yang dimiliki
            sendiri.</span
          ></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            >1.3. Dokumen ini mencakup profil perusahaan, pernyataan adanya
            risiko, dan dokumen aturan perdagangan (trading rules) yang wajib
            diikuti oleh Pihak Kedua sebelum melakukan transaksi Aset Kripto di
            Pasar Fisik Aset Kripto.</span
          ></span
        ></span
      >
    </p>

    <p>
      <br />
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            ><strong>PASAL 2 - PERJANJIAN PELANGGAN ASET KRIPTO</strong></span
          ></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            >2.1. Pihak pertama sepakat membuat sebuah perjanjian yang saling
            mengikat dan taat kepada hukum yang berlaku di Indonesia</span
          ></span
        ></span
      >
    </p>

    <p>&nbsp;</p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            ><strong>PASAL 3 - PEMBACAAN DAN PENYETUJUAN</strong></span
          ></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            >3.1. Pihak Kedua wajib membaca dan menyetujui setiap informasi dan
            pernyataan yang terdapat dalam dokumen-dokumen yang disampaikan oleh
            Pihak Pertama.</span
          ></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            >3.2. Pihak Kedua harus memberikan persetujuan melalui formulir yang
            disediakan dalam aplikasi atas dokumen-dokumen tersebut sebelum
            menempatkan dana transaksi pada rekening terpisah atau menempatkan
            Aset Kripto pada Wallet yang dimiliki oleh Pihak Pertama.</span
          ></span
        ></span
      >
    </p>

    <p>&nbsp;</p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            ><strong>PASAL 4 - RESIKO ASET KRIPTO</strong></span
          ></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            >4.1. Pihak kedua setuju bahwa perdagangan aset kripto mempunyai
            resiko.</span
          ></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            >4.2. Jika pihak kedua rugi dalam perdagangan, maka pihak pertama
            tidak dapat dituntut karena pihak pertama merupakan penyedia tempat
            berdagang saja.</span
          ></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            >4.3. Pihak pertama menyediakan sistem notifikasi yang akan
            menginformasikan pergerakan harga yang tidak wajar pada pasar.</span
          ></span
        ></span
      >
    </p>

    <p>&nbsp;</p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            ><strong>PASAL 5 - PERSYARATAN PELANGGAN ASET KRIPTO</strong></span
          ></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            >5.1. Pihak Kedua harus memenuhi persyaratan berikut untuk menjadi
            Pelanggan Aset Kripto:</span
          >
        </span></span
      >
    </p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            >a. Berusia 17 tahun atau lebih.</span
          ></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            >b. Memiliki dokumen identifikasi resmi yang sesuai.</span
          ></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            >c. Menggunakan dana atau Aset Kripto yang dimiliki sendiri dan
            bukan hasil tindak pidana, pencucian uang, pendanaan terorisme, atau
            senjata pemusnah massal.</span
          ></span
        ></span
      >
    </p>

    <p>&nbsp;</p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            ><strong>PASAL 6. ATURAN PERDAGANGAN</strong></span
          ></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 11pt"
        ><span style="font-family: Arial, sans-serif"
          ><span style="color: #000000">6.1. DEPOSIT</span></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 11pt"
        ><span style="font-family: Arial, sans-serif"
          ><span style="color: #000000">6.1.1. Deposit Rupiah</span></span
        ></span
      >
    </p>

    <ol>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan mengisi formulir deposit Rupiah dengan jumlah yang
              diinginkan.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Setelah disimpan, aplikasi akan mengirimkan instruksi deposit,
              seperti; nomor akun, nama akun dan nama bank.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan upload bukti transfer.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pada back office, Admin akan melakukan persetujuan atau menolak
              deposit tersebut berdasarkan bukti yang diupload.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Jika disetujui saldo pelanggan dalam Rupiah akan bertambah.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Jika tidak disetujui, saldo Rupiah tidak bertambah dan pelanggan
              wajib mengupload kan yang sesuai.</span
            ></span
          ></span
        >
      </li>
    </ol>

    <p>&nbsp;</p>

    <p>
      <span style="font-size: 11pt"
        ><span style="font-family: Arial, sans-serif"
          ><span style="color: #000000">6.1.2. Deposit Kripto</span></span
        ></span
      >
    </p>

    <ol>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan mengunjungi halaman deposit sesuai dengan kripto yang
              diinginkan.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan mencatat alamat deposit kripto dengan benar.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan melakukan deposit kripto melalui wallet sendiri atau
              transfer dari wallet exchange lainnya.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Aplikasi akan secara otomatis memasukkan ke dalam database jika
              menerima deposit pada alamat yang ada pada pelanggan.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Saldo pelanggan akan bertambah secara otomatis sesuai kripto yang
              di depositkan.</span
            ></span
          ></span
        >
      </li>
    </ol>

    <p>&nbsp;</p>

    <p>
      <span style="font-size: 11pt"
        ><span style="font-family: Arial, sans-serif"
          ><span style="color: #000000"
            >6.2. Transaksi Jual Dan Beli</span
          ></span
        ></span
      >
    </p>

    <ol>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Untuk melalukan transaksi jual dan beli, pelanggan klik menu
              &ldquo;Exchange / Pertukaran&rdquo;, lalu klik aset kripto yang
              ingin ditransaksikan.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan melakukan transaksi beli ketika order close akan
              terlihat notifikasi pembelian.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Dalam Laman Website Penjual pun akan ada Notifikasi atas order
              close tersebut.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan juga dapat melihat History Transaksi yang dilakukan,
              secara real-time.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Jika pelanggan ingin melakukan transaksi Jual, tinggal klik saja
              transaksi yang ingin dijual, lalu akan muncul notifikasi seperti
              pada saat transaksi pembelian.</span
            ></span
          ></span
        >
      </li>
    </ol>

    <p>&nbsp;</p>

    <p>
      <span style="font-size: 11pt">
        <span style="font-family: Arial, sans-serif">
          <span style="color: #000000">
            6.3. Limit Order &amp; Market Order
          </span>
        </span>
      </span>
    </p>

    <p>
      <span style="font-size: 11pt">
        <span style="font-family: Arial, sans-serif">
          <span style="color: #000000"> 6.3.1. Limit Order</span></span
        ></span
      >
    </p>

    <ol>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Limit Order adalah pelanggan yang meletakkan order beli atau jual
              tetapi menunggu untuk terpenuhi. Order jual dan order beli akan
              tertera pada orderbook.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan masuk ke halaman pertukaran</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan memilih pasar yang diinginkan</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan menginputkan beli atau jual dan kuantitas yang
              diinginkan.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan menentukan harga jual atau harga beli</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan menunggu proses jual dan beli sampai ada pelanggan lain
              yang mengambil.</span
            ></span
          ></span
        >
      </li>
    </ol>

    <p>&nbsp;</p>

    <p>
      <span style="font-size: 11pt"
        ><span style="font-family: Arial, sans-serif"
          ><span style="color: #000000">6.3.2. Market Order</span></span
        ></span
      >
    </p>

    <ol>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Market Order adalah pelanggan yang langsung meletakkan jual atau
              beli tepat pada orderbook sehingga jual/beli langsung
              terpenuhi.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan masuk ke halaman pertukara..</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan memilih pasar yang diinginkan</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan menginputkan beli atau jual dan kuantitas yang
              diinginkan.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan langsung mengambil order yang tertera di
              orderbook.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Jika harga terpenuhi dan kuantitas cukup, maka saldo akan
              bertambah.</span
            ></span
          ></span
        >
      </li>
    </ol>

    <p>&nbsp;</p>

    <p>
      <span style="font-size: 11pt"
        ><span style="font-family: Arial, sans-serif"
          ><span style="color: #000000">6.4. Biaya transaksi</span></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 11pt"
        ><span style="font-family: Arial, sans-serif"
          ><span style="color: #000000"
            >Biaya transaksi di bursa kripto akan dijelaskan sebagai berikut
            :</span
          ></span
        ></span
      >
    </p>

    <ol>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Deposit IDR: untuk melakukan deposit Rupiah (IDR) minimal deposit
              : Rp. 10.000,00</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pembelian: pelanggan dapat mulai melakukan pembelian dengan
              minimal pembelian: Rp. 10.000,00</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Biaya Deposit : Virtual Account (VA) dikenakan 3000 - 4500, dan 0
              jika tidak menggunakan VA</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Biaya withdraw aset digital disesuaikan dan berbeda-beda antara
              satu dan lainnya. Pelanggan diharapkan membaca dengan teliti
              sebelum melakukan withdraw.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Biaya Trading : 0.3 % sampai dengan 0.5%</span
            ></span
          ></span
        >
      </li>
    </ol>

    <p>&nbsp;</p>

    <p>
      <span style="font-size: 11pt"
        ><span style="font-family: Arial, sans-serif"
          ><span style="color: #000000"
            >6.5. Ketentuan Penarikan dan Pengiriman Transaksi</span
          ></span
        ></span
      >
    </p>

    <ol>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000">
              Everest Kripto Indonesia berhak untuk menunda dan/atau menolak
              suatu transaksi jika bursa kripto mengetahui atau sedang
              mempertimbangkan, menduga bahwa aktivitas penipuan dan/atau
              kejahatan telah dan/atau akan dilaku kan.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan setuju dan mengakui bahwa sejauh diizinkan oleh
              ketentuan hukum yang berlaku, Everest Kripto Indonesia berhak
              menolak untuk memproses transaksi apa pun.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan mengakui bahwa bursa kripto tunduk pada undang-undang
              tentang kejahatan keuangan, termasuk tetapi tidak terbatas pada,
              Undang-Undang Pemberantasan Korupsi dan Undang-Undang tentang
              Pencucian Uang yang berlaku di Indonesia dan internasional, setiap
              undang-undang yang berlaku di Indonesia dan kebijakan internal
              bursa kripto. Untuk tujuan ini, Pelanggan dengan ini setuju bahwa
              semua informasi yang diminta oleh bursa kripto untuk memenuhi
              hukum dan peraturan termasuk tetapi tidak terbatas pada nama,
              alamat, usia, jenis kelamin, informasi identitas pribadi,
              pendapatan, pekerjaan, properti, hutang, sumber kekayaan, tujuan
              pembukaan rekening, tujuan investasi, rencana keuangan atau
              informasi keuangan lain yang relevan dari pelanggan. Jika
              diperlukan oleh bursa kripto, pelanggan juga setuju untuk
              menggunakan data terbaru tentang informasi tersebut kepada bursa
              kripto.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Bursa kripto berkewajiban untuk mematuhi undang-undang, peraturan
              dan permintaan untuk lembaga masyarakat dan lembaga pemerintah di
              yurisdiksi yang berbeda yang berkaitan dengan pencegahan
              pembiayaan untuk, antara lain, teroris dan pihak yang terkena
              sanksi. Hal ini dapat menyebabkan bursa kripto untuk mencegat dan
              menyelidiki semua perintah pembayaran dan informasi atau
              komunikasi lain yang dikirim ke atau oleh pelanggan, atau atas
              nama pelanggan melalui sistem bursa kripto. Proses ini juga dapat
              melibatkan bursa kripto untuk melakukan penyelidikan lebih lanjut
              untuk menentukan apakah nama yang muncul di semua transaksi yang
              dilakukan atau akan dilakukan oleh pelanggan melalui akun mereka
              adalah nama teroris.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Bursa kripto tidak akan bertanggung jawab atas segala kerusakan
              (baik langsung, dan termasuk namun tidak terbatas pada kehilangan
              keuntungan atau bunga) atau kerusakan yang diderita oleh pihak
              manapun yang timbul dari keterlambatan atau kelalaian bursa kripto
              untuk memproses semua pembayaran perintah atau informasi atau
              komunikasi lain atau untuk melakukan kewajiban lainnya, yang
              mengakibatkan seluruh atau sebagian dari tindakan yang diambil
              berdasarkan ketentuan angka 4 penolakan dan Penangguhan transaksi
              ini.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Bursa kripto berwenang untuk memantau akun pelanggan untuk
              mencegah kejahatan keuangan.</span
            ></span
          ></span
        >
      </li>
    </ol>

    <p>&nbsp;</p>

    <p>
      <span style="font-size: 11pt"
        ><span style="font-family: Arial, sans-serif"
          ><span style="color: #000000">6.6. Transaksi Mencurigakan</span></span
        ></span
      >
    </p>

    <ol>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Dalam hal terjadi transaksi mencurigakan yang dilakukan melalui
              fasilitas bursa kripto, maka bursa kripto berhak
              menghentikan/menonaktifkan akun bursa kripto pada pelanggan dan
              memblokir dana transaksi serta menunda transaksi kepada pelanggan,
              sampai dengan pemberitahuan dari bursa kripto.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Dalam hal terjadi transaksi mencurigakan dan/atau transaksi yang
              melebihi batas yang ditetapkan oleh volume transaksi bursa kripto
              terhadap pelanggan dengan alasan apapun, maka bursa kripto berhak
              setiap saat menunda pengkreditan dana ke akun pelanggan bursa
              kripto dan/atau pemblokiran pelanggan e-wallet sampai proses
              investigasi selesai dalam jangka waktu yang ditentukan oleh bursa
              kripto.</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Apabila terbukti transaksi pada angka 1 dan 2 tersebut di atas
              mengenai transaksi mencurigakan merupakan transaksi yang melanggar
              SKU dan/atau peraturan perundang-undangan yang berlaku, maka
              pelanggan dengan ini menggunakan kuasa kepada bursa kripto untuk
              mendebet aset kripto di e-wallet pelanggan untuk mengganti
              kerugian bursa kripto yang timbul dari transaksi tersebut, tanpa
              mengurangi hak bursa kripto untuk menuntut ganti rugi atas semua
              kerugian yang timbul dari transaksi tersebut dan pelanggan dengan
              ini setuju bahwa bursa kripto tidak berkewajiban untuk
              mengembalikan dana yang tertunda tanpa pengecualian oleh bursa
              kripto atau dana yang diblokir sebagaimana dimaksud pada angka 2
              Ketentuan mengenai transaksi mencurigakan ini.</span
            ></span
          ></span
        >
      </li>
    </ol>

    <p>&nbsp;</p>

    <p>
      <span style="font-size: 11pt"
        ><span style="font-family: Arial, sans-serif"
          ><span style="color: #000000">6.7. Batas Penarikan</span></span
        ></span
      >
    </p>

    <ol>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan dengan ini setuju untuk mematuhi baik batas penarikan
              aset kripto maupun uang yang berlaku pada akun pelanggan, dan
              bursa kripto yang dalam hal ini menerapkan prinsip Anti Money
              Laundering (AML) sesuai dengan peraturan Pemerintah Republik
              Indonesia diberi kuasa dan hak untuk tidak memproses transaksi
              yang telah melampaui batas penarikan harian sesuai level tiap
              levelnya.</span
            ></span
          ></span
        >
      </li>
    </ol>

    <p>&nbsp;</p>

    <p>
      <span style="font-size: 11pt"
        ><span style="font-family: Arial, sans-serif"
          ><span style="color: #000000"
            >6.8. Formula Perhitungan Transaksi</span
          ></span
        ></span
      >
    </p>

    <ol>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pembelian Aset Kripto dapat dilakukan menggunakan Token/ Koin
              berdasarkan jenis kontrak yang akan diperdagangkan;</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Mata Uang yang dipakai untuk melakukan transak si Aset Kripto
              adalah Rupiah;</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Rumus perhitungan total nilai aset beli:</span
            ></span
          ></span
        ><br />
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >TOTAL NILAI ASET BELI= Harga Beli x Jumlah Koin</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Rumus perhitungan total nilai aset jual:</span
            ></span
          ></span
        ><br />
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >TOTAL NILAI ASET= Harga Jual x Jumlah Koin</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Formula perhitungan untung / rugi:</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Formula perhitungan untung rugi secara realtimedapat dilakukan
              dengan perhitungan sebagai berikut:</span
            ></span
          ></span
        >
      </li>
    </ol>

    <p style="margin-left: 48px">
      <span style="font-size: 11pt"
        ><span style="font-family: Arial, sans-serif"
          ><span style="color: #000000"
            >BELI =Harga Beli &ndash; Harga Pasar</span
          ></span
        ></span
      >
    </p>

    <ol start="7">
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >JUAL =Harga Pasar &ndash; Harga Jual</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Formula perhitungan saldo (equity)</span
            ></span
          ></span
        ><br />
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >SALDO (EQUITY) =Total Saldo Kripto + Total Saldo Dana</span
            ></span
          ></span
        >
      </li>
    </ol>

    <p>
      <span style="font-size: 11pt"
        ><span style="font-family: Arial, sans-serif"
          ><span style="color: #000000"
            >6.9. Ketentuan Pelaksanaan Transaksi</span
          ></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 11pt"
        ><span style="font-family: Arial, sans-serif"
          ><span style="color: #000000"
            >6.9.1. TRANSAKSI JUAL/BELI ASET KRIPTO</span
          ></span
        ></span
      >
    </p>

    <ol>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan Aset Kripto dapat melakukan transaksi apabila yang
              bersangkutan memiliki kecukupan dana dan/atau saldo Aset
              Kripto;</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pedagang Fisik Aset Kripto dilarang memfasilitasi transaksi
              apabila Pelanggan tidak memiliki kecukupan dana dan/atau saldo
              Aset Kripto;</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan dapat melaklukan transaksi jual/beli di dalam sistem
              transaksi Calon Pedagang Aset Kripto</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan dapat menggunakan 2 jenis order yang disediakan di
              dalam sistem transaksi, yaitu Market order dan Limit Order</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Apabila pelanggan menggunakan Market Order maka, transaksi
              nasabah akan terjadi di harga terbaik yang tersedia pada saat
              itu</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Apabila pelanggan menggunakan limit order maka nasabah akan
              mendapatkan harga sesuai dengan harga yang diinginkan yang
              dipasang pada sistem perdagangan</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan dapat melihat Riwayat transaksinya ataupun jumlah open
              order di dalam sistem perdagangan</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan dapat melakukan pembatalan atas limit order yang
              dipasangnya selama order belum terisi (filled)</span
            ></span
          ></span
        >
      </li>
    </ol>

    <p>&nbsp;</p>

    <p>
      <span style="font-size: 11pt"
        ><span style="font-family: Arial, sans-serif"
          ><span style="color: #000000">6.9.2. Pembatalan Transaksi</span></span
        ></span
      >
    </p>

    <ol>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000">
              Everest Kripto Indonesia berhak untuk melakukan penundaan dan/atau
              pembatalan transaksi berdasarkan pertimbangan dan dugaan bahwa
              terjadi kesalahan harga, kegiatan penipuan dan/atau aksi kejahatan
              telah dan/atau akan dilakukan;</span
            ></span
          ></span
        >
      </li>
    </ol>

    <ol>
      <li style="list-style-type: decimal">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan Aset Kripto setuju dan mengakui bahwa sepanjang
              diperbolehkan oleh ketentuan Hukum yang berlaku, Everest Kripto
              Indonesia berhak menolak untuk memproses segala transaksi;</span
            ></span
          ></span
        >
      </li>
    </ol>

    <ol start="2">
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000">
              Everest Kripto Indonesia berwenang untuk melakukan pemantauan atas
              Akun Pelanggan Aset Kripto dalam rangka pencegahan kejahatan
              keuangan;</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pelanggan Aset Kripto mengerti, memahami dan setuju bahwa
              terhadap setiap transaksi yang telah dilakukan melalui Calon
              Pedagang Fisik Aset Kripto bersifat final dan tidak dapat
              dilakukan pembatalan oleh Pelanggan Aset Kripto;</span
            ></span
          ></span
        >
      </li>
      <li style="list-style-type: lower-alpha">
        <span style="font-size: 11pt"
          ><span style="font-family: Arial, sans-serif"
            ><span style="color: #000000"
              >Pedagang Fisik Aset Kripto tidak akan bertanggung jawab untuk
              setiap kerugian (baik secara langsung dan termasuk namun tidak
              terbatas pada kehilangan keuntungan atau bunga) atau kerugian yang
              diderita oleh pihak manapun yang timbul dari segala penundaan atau
              kelalaian dari Calon Pedagang Fisik Aset Kripto untuk memproses
              segala perintah pembayaran tersebut atau informasi atau komunikasi
              lainnya atau untuk melaksanakan segala kewajiban lainnya, yang
              disebabkan secara keseluruhan atau sebagian oleh segala tindakan
              yang diambil berdasarkan ketentuan Penolakan dan Penundaan
              Transaksi ini;</span
            ></span
          ></span
        >
      </li>
    </ol>

    <p>&nbsp;</p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif"
          ><span style="color: #000000"
            ><strong>PASAL 7 - BATASAN AKUN</strong></span
          ></span
        ></span
      >
    </p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            >7.1. Pihak Kedua hanya dapat membuka satu akun untuk setiap
            identitas yang sama di PT EVEREST KRIPTO INDONESIA.</span
          ></span
        ></span
      >
    </p>

    <p>&nbsp;</p>

    <p>
      <span style="font-size: 12pt"
        ><span style="font-family: Roboto, sans-serif">
          <span style="color: #000000"
            >Demikian surat perjanjian ini dibuat dan ditandatangani oleh kedua
            belah pihak sebagai tanda persetujuan mereka terhadap semua
            ketentuan yang tercantum di dalamnya.</span
          ></span
        ></span
      >
    </p>

    <p>&nbsp;</p>

    <table width="100%">
      <tr>
        <td style="background-color: #fff" width="50%">
          Jakarta, {{ user.orang_agreement_date }}
        </td>
        <td style="background-color: #fff">&nbsp;</td>
      </tr>
      <tr>
        <td style="background-color: #fff">
          <p>
            <span style="font-size: 12pt">
              <span style="font-family: Roboto, sans-serif">
                <span style="color: #000000"
                  ><strong>PT EVEREST KRIPTO INDONESIA</strong></span
                ></span
              ></span
            >
          </p>

          <p>
            <span style="font-size: 12pt">
              <span style="font-family: Roboto, sans-serif">
                <span style="color: #000000"
                  >Aplikasi Everest Kripto Indonesia
                </span></span
              ></span
            >
          </p>
        </td>
        <td style="background-color: #fff">
          <p>
            <span style="font-size: 12pt">
              <span style="font-family: Roboto, sans-serif">
                <span style="color: #000000"
                  ><strong> Pelanggan</strong></span
                ></span
              ></span
            >
          </p>
          <p>
            <span style="font-size: 12pt">
              <span style="font-family: Roboto, sans-serif">
                <span style="color: #000000">
                  {{ user.orang_name }}
                </span>
              </span>
            </span>
          </p>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
// node_modules

// applibs

// components

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>
