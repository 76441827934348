<template>
  <div>
    <TopNav />

    <div class="settings mtb15">
      <div class="container-fluid">
        <div class="row">
          <Menu />

          <div class="col-md-12 col-lg-9">
            <HeadBalance
              :currency="currency"
              :activeCurrencyObj="ccurrencyObj"
            />

            <ul class="nav nav-pills mb-3">
              <li class="nav-item">
                <a class="nav-link deposit-head active text-white" href="#">
                  Deposit
                </a>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link withdraw-head text-white"
                  :to="`/settings/wallets/idr_withdraw`"
                >
                  Withdraw
                </router-link>
              </li>
            </ul>

            <div class="card">
              <div class="card-body">
                <h5 class="card-title">&nbsp;</h5>
                <div class="row">
                  <div class="col">
                    <div class="alert alert-warning">
                      <p class="text-dark">
                        Silahkan transfer kepada
                        rekening berikut:
                        <br />
                        Nama Bank: PT. Bank Central Asia
                        <br />
                        Nomor Akun: 000 000 0000
                        <br />
                        Nama Akun: PT. Everest Kripto Indonesia 
                      </p>
                    </div>

                    <form @submit.prevent="submitFormDeposit">
                      <div class="form-row mt-4">
                        <div class="col-md-12">
                          <label>Jumlah Deposit:</label>
                          <input
                            type="text"
                            v-model="deposit.amount"
                            class="form-control"
                          />
                        </div>

                        <div class="col-md-12 mt-2">
                          <button type="submit" class="btn btn-light">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Daftar Deposit</h5>
                <div class="wallet-history">
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="text-left">No.</th>
                        <th class="text-left">Date</th>
                        <th class="text-left">Status</th>
                        <th class="text-right">Jumlah</th>
                        <th class="text-left">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in deposits" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>
                          {{
                            moment(item.created_at).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )
                          }}
                        </td>
                        <td>{{ item.depoidr_status }}</td>
                        <td class="text-right">
                          {{ parseFloat(item.depoidr_amount).numberFormat(2) }}
                        </td>
                        <td>
                          <div v-if="item.depoidr_status == 'PENDING'">
                            <router-link
                              :to="`/settings/wallets/idr_deposit/upload-file?id=${item.id}`"
                            >
                              Unggah Bukti Bayar
                            </router-link>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
import moment from "moment";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import Menu from "@/components/Settings/Menu.vue";
import HeadBalance from "@/components/Settings/Wallets/HeadBalance.vue";

export default {
  components: {
    TopNav,
    Footer,
    Menu,
    HeadBalance,
  },
  data() {
    return {
      themain,
      moment,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      currency: "IDR",
      ccurrencyObj: {
        ccurrency_config: {
          address_view: "",
          deposit_info_view: "",
          deposit_manual_view: "",
        },
      },

      deposits: [],

      user: {},

      deposit: {
        amount: 0,
      },
    };
  },

  async beforeMount() {
    var self = this;
    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    } else {
      self.$router.push("/");
    }
  },

  async mounted() {
    var self = this;
    await self.getCcurrencyData();
    await self.getUserData();

    // ambil data deposit
    await self.getDeposits();
  },

  methods: {
    async getUserData() {
      var self = this;

      // ambil data dia ketika login
      if (self.token != null && self.token != "") {
        var res = await thestore.getMyProfileData(self.token);
        self.user = res;
      } else {
        self.$router.push("/");
      }
    },

    async getCcurrencyData() {
      var self = this;
      var filter_rules = [
        { field: "ccurrency_symbol", op: "equal", value: self.currency },
      ];
      var res = await thestore.getCurrencies(1, 1, "id", "desc", filter_rules);
      self.ccurrencyObj = res.datas[0];
    },

    async getDeposits() {
      var self = this;
      var filter_rules = [];
      var depoRes = await thestore.getWalletsIdrDeposits(
        self.token,
        1,
        10,
        "id",
        "desc",
        filter_rules
      );
      self.deposits = depoRes.datas;
    },

    async submitFormDeposit() {
      var self = this;
      var res = await thestore.postWalletsIdrDeposit(
        self.token,
        self.deposit.amount
      );
      if (res.success == true) {
        self.$router.go();
      } else {
        alert(res.msg);
      }
    },
  },
};
</script>