<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Daftar Deposit</h5>
      <div class="wallet-history">
        <table class="table">
          <thead>
            <tr>
              <th class="text-left">No.</th>
              <th class="text-left">Date</th>
              <th class="text-left">Chain ID</th>
              <th class="text-left">TxHash</th>
              <th class="text-right">Jumlah</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in ccdeposits" :key="item.id">
              <td>{{ item.id }}</td>
              <td>{{ item.created_at }}</td>
              <td class="text-right">{{ item.evm_chain_id }}</td>
              <td>{{ item.ccdeposit_txhash }}</td>
              <td class="text-right">{{ item.ccdeposit_amount }}</td>
              <td>
                <div class="btn btn-primary btn-sm" @click="openModalKyt(item)"
                v-if="item.kyt_name == '' || item.kyt_name == null">
                  KYT
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- modal deposit KYT-->
        <div
          class="modal fade text-dark"
          tabindex="-1"
          aria-hidden="true"
          v-bind:id="`kyt-modal`"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Know Your Transaction</h5>
                <button
                  type="button"
                  class="btn-close"
                  aria-label="Close"
                  @click="themain.closeModal(`kyt-modal`)"
                ></button>
              </div>
              <div class="modal-body">
                
                <!-- id -->
                <input type="text" v-model="kytForm.ccdeposit_id" class="form-control" style="display: none;" />

                <div class="row mt-1">
                  <label class="col-md-4 text-right text-dark">Nama Pengirim :</label>
                  <div class="col-md-8">
                    <input type="text" v-model="kytForm.kyt_name" class="form-control" />
                  </div>
                </div>

                <div class="row mt-1">
                  <label class="col-md-4 text-right text-dark">Alamat Pengirim :</label>
                  <div class="col-md-8">
                    <input type="text" v-model="kytForm.kyt_home_address" class="form-control" />
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="themain.closeModal(`kyt-modal`)"
                  :disabled="modalCloseBtnDisabled"
                >
                  Batal
                </button>
                <button
                  type="button"
                  class="btn btn-dark"
                  @click="submitKyt"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
export default {
  components: {},
  props: {
    currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      ccdeposits: [],

      kytForm: {
        ccdeposit_id: "",
        kyt_name: "",
        kyt_home_address: ""
      }
    };
  },

  async mounted() {
    var self = this;
    await self.getDeposits();
  },

  methods: {
    async getDeposits() {
      var self = this;
      var filter_rules = [
        { field: "ccurrency_symbol", op: "equal", value: self.currency },
      ];
      var depoRes = await thestore.getCcdeposits(
        self.token,
        1,
        10,
        "id",
        "desc",
        filter_rules
      );
      self.ccdeposits = depoRes.datas;
    },

    async openModalKyt(item) {
      var self = this;

      // inject data id saja
      self.kytForm['ccdeposit_id'] = item.id;

      themain.openModal('kyt-modal');
    },

    async submitKyt() {
      var self = this;
      await thestore.postKytCcdeposit(
        Cookies.get("jwt"), 
        {
          ccdeposit_id: self.kytForm.ccdeposit_id,
          kyt_name: self.kytForm.kyt_name,
          kyt_home_address: self.kytForm.kyt_home_address
        }
      );
      themain.closeModal('kyt-modal');
      self.$router.go();
    }
  },
};
</script>
