<template>
  <div class="col-md-2">
    <div class="market-hide-show" id="exchange-page--market-element">
      <div class="market-pairs">
        <div class="tab-content">
          <div class="tab-pane fade show active" id="BTC" role="tabpanel">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-left">Pasar</th>
                  <th>Harga</th>
                  <th>Perubahan</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in markets" :key="item.id">
                  <td class="text-left cursor-pointer" @click="gotoMarket(item)">
                    <img
                      :src="item.currencies[0].ccurrency_icon_data_url"
                      style="border-radius: 100%; height: 16px"
                    />
                    {{ item.pair_show }}
                  </td>
                  <td :class="`text-right cursor-pointer ${item.theclass}`" @click="gotoMarket(item)">
                    {{ parseFloat(item.last_price).numberFormat(2) }}
                  </td>
                  <td :class="`text-right cursor-pointer ${item.theclass}`" @click="gotoMarket(item)">
                    {{ parseFloat(item.change_24h).numberFormat(2) }}%
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// node_modules

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components

export default {
  components: {},
  data() {
    return {
      themain,

      markets: [],
      nomarkets: [],

      modalItem: {
        simbols: ["a", "b"],
        a_uni_icon: "",
        b_uni_icon: "",
      },
    };
  },

  sockets: {
    connect: function () {
      console.log("socket connected -- market section");
    },

    market_update: function (data) {
      var self = this;
      // lakukan perulangan markets
      self.markets.forEach(function (el, i) {
        console.log("el", el);
        if (el.pair == data.pair) {
          var newData = JSON.parse(data.jsontable);
          console.log("newData", newData);

          self.markets[i].last_price = newData.last_price;
          self.markets[i].change_24h = newData.change_24h;
        }
      });
    },
  },

  async mounted() {
    var self = this;

    await self.getMarkets();

    self.emitter.on("fe-market_update", async function (e) {
      if (e.data) {
        var lamas = self.markets; // tampung data lama
        self.markets = []; // kosongkan markets
        var barus = [];

        lamas.forEach((ele) => {
          if (ele.id == e.data.id) {
            barus.push(e.data);
          } else {
            barus.push(ele);
          }
        });
        await self.upgradeMarkets(barus);
      }
    });

    // listen apakah ada show hide market untuk screen kecil
    self.emitter.on("fe-show-hide-market-for-small-screen", function () {
      var el = document.getElementById("exchange-page--market-element");

      console.log("he", el.clientHeight);

      // tidak bisa mendetek display karena hasilnya jika pindah halaman, jadi bug
      // sehingga disini kita tambahkan saja client heigh dan lebih baik detek client heigh saja
      if (el.clientHeight > 0) {
        el.style.display = "none";
        el.style.height = "0px";
      }

      if (el.clientHeight == 0) {
        el.style.display = "block";
        el.style.height = "300px";
      }
    });
  },
  methods: {
    async getMarkets() {
      var self = this;
      var res = await thestore.getMarkets(1, 20, "id", "desc", []);

      console.log("res 00000", res);

      await self.upgradeMarkets(res.datas);
    },

    async upgradeMarkets(plain_datas) {
      var self = this;

      // data harus kita olah lagi
      var mars = [];
      plain_datas.forEach((ele) => {
        // parsing
        var simbols = ele.pair.split("_");
        ele["pair_show"] = `${simbols[0]}/${simbols[1]}`;
        ele["simbols"] = simbols;

        if (ele.change_24h >= 0) {
          ele["theclass"] = "green";
        } else {
          ele["theclass"] = "red";
        }

        // cegah NaN
        if(ele.change_24h == null) {
          ele["change_24h"] = 0;
        }

        mars.push(ele);
      });

      self.markets = mars;
    },

    openModal(theitem) {
      let self = this;

      let imgAUnique = themain.iconB64Text(theitem.a_address.slice(2, 42));
      let imgBUnique = themain.iconB64Text(theitem.b_address.slice(2, 42));

      theitem.a_uni_icon = imgAUnique;
      theitem.b_uni_icon = imgBUnique;

      self.modalItem = theitem;

      console.log("item", theitem);

      themain.openModal("exchange-market-section-modal");
    },

    gotoMarket(item) {
      var self = this;
      self.$router.push({
        path: `/markets/${item.id}/${item.pair}`,
      });
    },
  },
};
</script>

<style scoped>
/* 
bagian ini membuat table yang berantakan menjadi rapi
lihat diskusi ini https://stackoverflow.com/questions/41534794/table-rows-not-aligning
sample berantakan: https://jsfiddle.net/fk6fnto4/
dirapikan: https://jsfiddle.net/fk6fnto4/1/
 */
.market-pairs thead tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.market-pairs tbody tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.market-pairs td,
th {
  padding: 6px;
  text-align: right;
  /* overflow-y: scroll; */
}

tbody tr td {
  word-wrap: break-word;
}
</style>
