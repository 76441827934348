<template>
  <div>
    <TopNav />

    <div class="settings mtb15 text-light">
      <div class="container">
        <div class="row mt-4 mb-4">
          <h1>Data Pribadi</h1>
        </div>

        <div class="row">
          <form @submit.prevent="submitForm" class="text-light">
            <div class="d-md-flex">
              <div class="col-md-12">
                <div class="form-group input">
                  <label>Nama Lengkap</label>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      required
                      placeholder="Nama Lengkap"
                      v-model="user.orang_name"
                    />
                  </div>
                  <span class="caution">
                    Nama lengkap yang Anda daftarkan harus sama dengan nama
                    lengkap pada kartu identitas Anda.
                  </span>
                </div>

                <div class="form-group input">
                  <label>Nomor Telepon</label>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      required
                      placeholder="Nomor Telpon"
                      v-model="user.orang_mobilenumber"
                    />
                  </div>
                  <span class="caution">
                    Anda dapat menggunakan kode negara (+62) atau 0.
                  </span>
                </div>

                <div class="form-group input">
                  <label>Tempat Lahir</label>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      required
                      placeholder="Tempat Lahir"
                      v-model="user.orang_birthplace"
                    />
                  </div>
                </div>

                <div class="form-group input date">
                  <label>Tanggal Lahir</label>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      required
                      placeholder="Tanggal Lahir"
                      v-model="user.orang_birthday"
                      style="display: none"
                    />
                    <VueDatePicker v-model="date" :format="format" />
                  </div>
                </div>

                <div class="form-group select">
                  <label>Jenis Kelamin</label>
                  <div>
                    <select class="form-control" v-model="user.orang_gender">
                      <option value="M">Pria</option>
                      <option value="F">Wanita</option>
                    </select>
                  </div>
                </div>

                <div class="form-group textarea">
                  <label>Alamat</label>
                  <div>
                    <textarea
                      v-model="user.orang_address"
                      class="form-control"
                      placeholder="Alamat"
                    ></textarea>
                  </div>
                </div>

                <div class="form-group input">
                  <label>Pekerjaan</label>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      required
                      placeholder="Pekerjaan"
                      v-model="user.orang_profession"
                    />
                  </div>
                </div>

                <div class="form-group input">
                  <label>Nomor Paspor</label>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Nomor Paspor"
                      v-model="user.orang_nik"
                    />
                  </div>
                </div>

                <div class="form-group file">
                  <label>Upload File Paspor</label>
                  <div>
                    <input
                      type="file"
                      class="form-control"
                      placeholder="Paspor File"
                      @change="handleNikFileUpload($event)"
                    />
                  </div>
                  <span class="caution"
                    >Hanya tipe gambar: jpg, jpeg, png, bmp | Max size:
                    1MB</span
                  >
                  <span class="caution"
                    >Gambar harus jelas dan minimum resolusi 100px x 100px</span
                  >
                </div>

                <div class="form-group select">
                  <label>Warga Negara</label>
                  <div>
                    <select class="form-control" v-model="user.orang_warganegara">
                      <option value="">Select a country</option>
                      <option v-for="country in countryList" :value="country" :key="country">{{ country }}</option>
                    </select>
                  </div>
                </div>
                
              </div>
            </div>

            <div class="action my-5">
              <div class="wrapper">
                <button class="btn btn-gradient">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { getNames } from 'country-list';

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";

export default {
  components: {
    TopNav,
    Footer,
    VueDatePicker,
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      user: {
        orang_nik_file: "",
      },
      orang_nik_file: "",
      namaAppB: process.env.VUE_APP_NAMA_APP_B,
      date: null,

      countryList: getNames(),
    };
  },

  async beforeMount() {
    var self = this;

    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    }
  },

  async mounted() {
    var self = this;

    // ambil data dia ketika login
    if (self.token != null && self.token != "") {
      var res = await thestore.getMyProfileData(self.token);
      self.user = res;
    } else {
      self.$router.push("/");
    }

    // checking KYC step
    await self.kycChecing();
  },

  methods: {
    format(date) {
      var self = this;

      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      var daySend = "0";
      if (day >= 10) {
        daySend = day;
      } else {
        daySend = `0${day}`;
      }

      var monthSend = "0";
      if (month >= 10) {
        monthSend = month;
      } else {
        monthSend = `0${month}`;
      }

      self.user["orang_birthday"] = `${year}-${monthSend}-${daySend}`;

      // return `Selected date is ${day}/${month}/${year}`;
      return `${year}-${monthSend}-${daySend}`;
    },

    async kycChecing() {
      var self = this;
      var res = await thestore.postKycCheckingStep(self.token);
      if (res.kyc_step != 1) {
        if(res.kyc_type == "Indonesia") {
          if (res.kyc_step == 0) {
            // selesai nol
            self.$router.push("/kyc/id/satu");
          } else if (res.kyc_step == 2) {
            // selesai 2
            self.$router.push("/kyc/id/tiga");
          } else if (res.kyc_step == 3) {
            // selesai 3
            self.$router.push("/kyc/id/empat");
          }
        }
        else {
          if (res.kyc_step == 0) {
            // selesai nol
            self.$router.push("/kyc/global/satu");
          } else if (res.kyc_step == 2) {
            // selesai 2
            self.$router.push("/kyc/global/tiga");
          } else if (res.kyc_step == 3) {
            // selesai 3
            self.$router.push("/kyc/global/empat");
          }
        }
      }
    },

    handleNikFileUpload(event) {
      this.orang_nik_file = event.target.files[0];
    },


    async submitForm() {
      var self = this;

      var formData = new FormData();
      formData.append("orang_name", self.user.orang_name);
      formData.append("orang_mobilenumber", self.user.orang_mobilenumber);
      formData.append("orang_birthplace", self.user.orang_birthplace);
      formData.append("orang_birthday", self.user.orang_birthday);
      formData.append("orang_gender", self.user.orang_gender);
      formData.append("orang_address", self.user.orang_address);
      formData.append("orang_profession", self.user.orang_profession);
      formData.append("orang_nik", self.user.orang_nik);
      formData.append("orang_warganegara", self.user.orang_warganegara);
      formData.append("orang_nik_file", self.orang_nik_file); // file

      var res = await thestore.postKycGlobalBasicInfo(self.token, formData);
      console.log("res", res);

      if (res.success == true) {
        self.$router.push("/kyc/global/tiga");
      } else {
        alert(res.msg);
      }
    },
  },
};
</script>
