<template>
  <div class="bg_container">
    <div class="home_container">
      <TopNav />

      <!-- Header section -->
      <div class="h_wrapper">
        <div class="h_left">
          <h2>
            Dive into the world of <br />
            crypto with BitBromo
          </h2>
          <p>
            Sign up today to be the part of the BitBromo - the easiest to buy &
            sell cripto
          </p>

          <div class="btn_wrapper" id="auth_menu_button">
            <router-link to="/auth/register">
              <div class="btn_signup">DAFTAR</div>
            </router-link>

            <router-link to="/auth/login">
              <div class="btn_login">MASUK</div>
            </router-link>
          </div>
        </div>

        <div class="h_right">
          <img src="/images/dex-tablet-lans.png" width="480" alt="" />
        </div>
      </div>

      <!-- why sections -->
      <div class="w_wrapper">
        <div class="w_left">
          <h2>Your trusted crypto exchange</h2>
          <p>Trade Anytime. Anywhere</p>
        </div>

        <div class="w_right">
          <div class="w_right-item">
            <div class="w_right-item-i">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="53"
                height="51"
                viewBox="0 0 53 51"
                fill="none"
              >
                <path
                  d="M9 9H51L45.75 31.75H14.25M45.75 38.75H16L7.25 2H2"
                  stroke="#FCFCFC"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M42.25 49.25C44.183 49.25 45.75 47.683 45.75 45.75C45.75 43.817 44.183 42.25 42.25 42.25C40.317 42.25 38.75 43.817 38.75 45.75C38.75 47.683 40.317 49.25 42.25 49.25Z"
                  stroke="#FCFCFC"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.5 49.25C21.433 49.25 23 47.683 23 45.75C23 43.817 21.433 42.25 19.5 42.25C17.567 42.25 16 43.817 16 45.75C16 47.683 17.567 49.25 19.5 49.25Z"
                  stroke="#FCFCFC"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="w_right-item-d">
              <h2>Simplicity</h2>
              <p>
                BitBromo makes it easy to buy and sell crypto using our mobile
                apps.
              </p>
            </div>
          </div>

          <div class="w_right-item">
            <div class="w_right-item-i">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="54"
                height="56"
                viewBox="0 0 54 56"
                fill="none"
              >
                <path
                  d="M27 0.00174995C18.9091 0.00174995 9.39002 1.93369 1.26246 6.13531L0 6.78104V8.22124C0 16.88 0.162168 39.9862 25.809 55.279L27 56L28.191 55.279C53.8378 39.9862 54 16.88 54 8.22124V6.80029L52.7463 6.14406C44.7181 1.92319 35.0909 0 27 0V0.00174995ZM27 4.69685C33.9209 4.69685 42.3117 6.47305 49.2588 9.82769C49.2396 18.8977 48.2875 36.7611 27 50.2584C5.71248 36.7611 4.74994 18.8977 4.74122 9.82769C11.7981 6.46605 20.0791 4.69685 27 4.69685ZM38.7057 17.6832C38.0971 17.7024 37.581 18.0839 37.2898 18.3762L24.7837 31.401L20.0966 25.7679C17.8803 23.1903 14.8392 25.1852 14.14 25.649L25.0121 38.3186L39.86 18.1382C39.7072 17.99 39.5267 17.8736 39.3289 17.7955C39.1311 17.7174 38.9199 17.6793 38.7074 17.6832H38.7057Z"
                  fill="#FCFCFC"
                />
              </svg>
            </div>
            <div class="w_right-item-d">
              <h2>Security</h2>
              <p>
                Security of yser information and funds is our first priority
              </p>
            </div>
          </div>
        </div>
        <div class="w_dash-prev">
          <img src="/images/dex-dashboard.png" width="810" alt="" />
        </div>
      </div>

      <div class="e_container">
        <div class="e_wrapper">
          <div v-for="item in itemsData" :key="item.id" class="e_item">
            <div class="e_item-b">
              <svg
                v-if="item.id == '01'"
                xmlns="http://www.w3.org/2000/svg"
                width="89"
                height="77"
                viewBox="0 0 74 62"
                fill="none"
              >
                <path
                  d="M46.2881 2.62018C49.5818 2.62018 52.7411 3.92985 55.0708 6.26188C57.4006 8.59399 58.7099 11.7575 58.7099 15.0567C58.7099 18.3559 57.4006 21.5194 55.0708 23.8515C52.7411 26.1835 49.5818 27.4932 46.2881 27.4932C42.9944 27.4932 39.8352 26.1835 37.5054 23.8515C35.1756 21.5194 33.8663 18.3559 33.8663 15.0567C33.8663 11.7575 35.1756 8.59399 37.5054 6.26188C39.8352 3.92985 42.9944 2.62018 46.2881 2.62018ZM21.0762 54.5318C21.0762 51.386 23.3059 48.0606 27.943 45.3822C32.514 42.7419 38.9945 41.0284 46.2881 41.0284C53.5818 41.0284 60.0622 42.7419 64.6332 45.3822C69.2703 48.0606 71.5 51.386 71.5 54.5318V59.5H21.0762V54.5318Z"
                  stroke="#EC2A06"
                  stroke-width="5"
                />
                <path
                  d="M9.30486 41.0485V19.1226H13.1083V41.0485H9.30486ZM0 31.946V28.2252H22.4132V31.946H0Z"
                  fill="#EC2A06"
                />
              </svg>

              <svg
                v-if="item.id == '02'"
                xmlns="http://www.w3.org/2000/svg"
                width="160"
                height="77"
                viewBox="0 0 210 127"
                fill="none"
              >
                <path
                  d="M105 2.5C112.458 2.5 119.612 5.46574 124.887 10.7456C130.161 16.0255 133.125 23.1872 133.125 30.6552C133.125 38.1232 130.161 45.2848 124.887 50.5648C119.612 55.8446 112.458 58.8103 105 58.8103C97.5416 58.8103 90.3881 55.8446 85.1135 50.5648C79.8388 45.2848 76.875 38.1232 76.875 30.6552C76.875 23.1872 79.8388 16.0255 85.1135 10.7456C90.3881 5.46574 97.5416 2.5 105 2.5ZM43.75 24.3966C47.6425 24.3966 51.2761 25.3089 54.5051 26.9695C53.6419 39.0147 57.1296 50.8799 64.0492 60.4543C59.8835 67.3376 52.3694 71.9483 43.75 71.9483C37.4519 71.9483 31.4113 69.4439 26.9571 64.9854C22.5028 60.5267 20 54.479 20 48.1724C20 41.8659 22.5028 35.8181 26.9571 31.3595C31.4113 26.9009 37.4519 24.3966 43.75 24.3966ZM166.25 24.3966C172.548 24.3966 178.589 26.9009 183.043 31.3595C187.497 35.8181 190 41.8659 190 48.1724C190 54.479 187.497 60.5267 183.043 64.9854C178.589 69.4439 172.548 71.9483 166.25 71.9483C157.631 71.9483 150.117 67.3376 145.951 60.4543C152.958 50.7605 156.336 38.9055 155.493 26.9704C158.723 25.3092 162.357 24.3966 166.25 24.3966ZM50.625 111.672C50.625 103.878 56.1187 96.3427 66.0332 90.6159C75.8816 84.9274 89.6529 81.3276 105 81.3276C120.347 81.3276 134.118 84.9274 143.967 90.6159C153.881 96.3427 159.375 103.878 159.375 111.672V124.5H50.625V111.672ZM2.5 124.5V113.862C2.5 109.001 5.81502 104.061 12.4202 99.8166C17.7616 96.3843 24.9746 93.6123 33.3922 91.9179C30.0433 97.5921 28.125 104.41 28.125 111.672V124.5H2.5ZM207.5 124.5H181.875V111.672C181.875 104.41 179.957 97.5921 176.608 91.9179C185.025 93.6123 192.238 96.3843 197.58 99.8166C204.185 104.061 207.5 109.001 207.5 113.862V124.5Z"
                  stroke="#EC2A06"
                  stroke-width="5"
                />
              </svg>

              <svg
                v-if="item.id == '03'"
                xmlns="http://www.w3.org/2000/svg"
                width="90"
                height="90"
                viewBox="0 0 108 108"
                fill="none"
              >
                <path
                  d="M79.0364 41.9377C79.0364 41.5108 78.8683 41.0738 78.542 40.7461C77.8895 40.0907 76.8217 40.0907 76.1691 40.7461L53.8344 63.1782L31.8259 41.0738C31.1734 40.4184 30.1056 40.4184 29.4531 41.0738C28.8005 41.7292 28.8005 42.8016 29.4531 43.457L52.648 66.763C53.3005 67.4184 54.3683 67.4184 55.0208 66.763L78.542 43.1392C78.8782 42.8016 79.0364 42.3747 79.0364 41.9377Z"
                  fill="#EC2A06"
                />
                <path
                  d="M16.6154 3H91.3846C98.9042 3 105 9.09582 105 16.6154V54C105 82.1665 82.1665 105 54 105C25.8335 105 3 82.1665 3 54V16.6154C3 9.09582 9.09582 3 16.6154 3Z"
                  stroke="#EC2A06"
                  stroke-width="6"
                />
              </svg>

              <svg
                v-if="item.id == '04'"
                xmlns="http://www.w3.org/2000/svg"
                width="90"
                height="90"
                viewBox="0 0 94 94"
                fill="none"
              >
                <rect
                  x="1.5"
                  y="1.5"
                  width="91"
                  height="91"
                  stroke="#D3321E"
                  stroke-width="3"
                />
                <path
                  d="M22.2949 38.4877V69.7054"
                  stroke="#D3321E"
                  stroke-width="3"
                  stroke-linecap="round"
                />
                <path
                  d="M46.6138 23.655V69.533"
                  stroke="#D3321E"
                  stroke-width="3"
                  stroke-linecap="round"
                />
                <path
                  d="M71.105 54.5277V70.0503"
                  stroke="#D3321E"
                  stroke-width="3"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <div class="e_item-t">
              <h2>{{ item.title }}</h2>
              <p>{{ item.desc }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="bg_wave">
      <img src="/images/bg.png" alt="" />
    </div> -->

      <Footer />
    </div>
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import MarketTable from "@/components/global/MarketTable.vue";

const items = [
  {
    id: "01",
    title: "Set up your account easily",
    desc: "make a few simple steps to start trading",
  },
  {
    id: "02",
    title: "Top up your account",
    desc: "Fund your account using any convenient method: cryptocurrency or fiat",
  },
  {
    id: "03",
    title: "Verify your identity",
    desc: "Go through the identity verification plroccess to secure your account and transactions",
  },
  {
    id: "04",
    title: "Star trading",
    desc: "You are ready to go! Star buying & selling crypto with BitBromo",
  },
];

export default {
  components: {
    TopNav,
    Footer,
    MarketTable,
  },

  data() {
    return {
      themain,

      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),

      itemsData: items,
    };
  },

  sockets: {
    connect: function () {
      console.log("socket connected");
    },

    market_update: function (data) {
      var self = this;

      var market = data.market;

      self.emitter.emit("fe-market_update", {
        data: market,
      });
    },
  },

  async beforeMount() {
    var self = this;

    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    }
  },

  mounted() {
    var self = this;

    if (self.token) {
      var selected = document.getElementById("auth_menu_button");
      if (selected) {
        selected.style.visibility = "hidden";
      }
    }
  },
};
</script>

<style scoped>
.bg_container {
  background: linear-gradient(
    180deg,
    rgba(36, 30, 72, 0.99) 31.87%,
    #351f43 76.68%
  );
}

.home_container {
  position: relative;
  height: 100%;
  background-image: url("/images/bg.png");
  background-repeat: no-repeat;
  background-position: bottom;
}

.h_wrapper {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 40px 20px;
}

.h_left h2 {
  font-size: 52px;
  color: #ffff;
  font-family: "Merriweather", serif;
}

.h_left p {
  max-width: 420px;
  font-size: 16px;
  font-weight: lighter;
  color: rgb(208, 211, 212);
  margin: 20px 0;
}

.btn_signup {
  background-color: #ec2a06;
  width: 200px;
  height: 40px;
  border-radius: 10px;
  border: none;
  color: #ffff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_wrapper {
  display: flex;
  gap: 20px;
}

.btn_signup:hover {
  background-color: #c22307;
}

.btn_login {
  width: 200px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #ec2a06;
  color: #ec2a06;

  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_login:hover {
  border: 1px solid #c22307;
  color: #c22307;
}

.w_wrapper {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
}

.w_left {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.w_left h2 {
  font-size: 34px;
  color: #ffff;
  text-align: center;
  font-family: "Merriweather", serif;
}

.w_left p {
  font-size: 16px;
  color: rgb(208, 211, 212);
}

.w_right {
  display: flex;
  gap: 20px;
  /* justify-content: space-around; */
}

.w_right-item {
  display: flex;
  align-items: center;
  gap: 20px;
}

.w_right-item-i {
  width: 80px;
  height: 80px;
  border: 1px solid #ec2a06;
  border-radius: 5px;
  padding: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffff;
}

.w_right-item-d h2 {
  font-size: 22px;
  color: #ffff;
  margin-bottom: 10px;
}

.w_right-item p {
  font-size: 16px;
  color: rgb(208, 211, 212);
  max-width: 300px;
}

.w_dash-prev {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 120px;
}

.w_dash-img {
  width: 70%;
  height: 800px;
  background-color: aquamarine;
  border-radius: 25px;
}

.e_container {
  display: flex;
  justify-content: center;
  margin-bottom: 120px;
}

.e_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 70px;
  margin-top: 120px;
}

.e_item {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.e_item-t {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.e_item h2 {
  color: #ffff;
  font-size: 24px;
}

.e_item p {
  max-width: 350px;
  font-size: 16px;
  color: rgb(208, 211, 212);
  text-align: center;
}

.e_item-b {
  margin-bottom: 20px;
}

.bg_wave {
  position: absolute;
  bottom: 9%;
}

@media only screen and (max-width: 960px) {
  .h_wrapper {
    flex-direction: column;
    gap: 30px;
  }
}

@media only screen and (max-width: 700px) {
  .w_right {
    flex-direction: column;
  }
}

@media only screen and (max-width: 601px) {
  .h_left h2 {
    font-size: 24px;
  }

  .w_left h2 {
    font-size: 22px;
  }

  .h_left p {
    font-size: 14px;
  }

  .w_right-item-d h2 {
    font-size: 16px;
  }

  .w_right-item-d p {
    font-size: 14px;
  }

  .w_right-item-i {
    width: 60px;
    height: 60px;
    padding: 10px;
  }

  .btn_wrapper {
    width: 100%;
    display: grid;
    grid-auto-columns: 1fr 1fr;
  }

  .btn_login,
  .btn_signup {
    width: 100%;
    height: 35px;
    font-size: 14px;
  }

  .e_wrapper {
    grid-template-columns: 1fr;
  }
}
</style>
