<template>
  <!-- card withdraws -->
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Daftar Withdraw</h5>
      <div class="wallet-history">
        <table class="table">
          <thead>
            <tr>
              <th class="text-left">No.</th>
              <th class="text-left">Waktu</th>
              <th class="text-right">Jumlah</th>
              <th class="text-right">Biaya</th>
              <th class="text-left">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in withdraws" :key="item.id">
              <td>{{ item.id }}</td>
              <td>{{ item.created_at }}</td>
              <td class="text-right">{{ item.withdraw_amount }}</td>
              <td class="text-right">{{ item.withdraw_fee }}</td>
              <td class="text-left">{{ item.withdraw_status }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components

export default {
  components: {},
  props: {
    currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      withdraws: [],
    };
  },

  async mounted() {
    var self = this;
    await self.getWithdraws();
  },

  methods: {
    async getWithdraws() {
      var self = this;
      var filter_rules = [
        { field: "ccurrency_symbol", op: "equal", value: self.currency },
      ];
      var res = await thestore.getWithdraws(
        self.token,
        1,
        10,
        "id",
        "desc",
        filter_rules
      );
      self.withdraws = res.datas;
    },
  },
};
</script>
